import { Avatar } from "@/components/ui/avatar"
import ButtonWithTooltip from "@/components/ui/custom/button-with-tooltip"
import { mapOfcountryList } from "@/helpers/countryList"
import { highlightMatch } from "@/helpers/highlightMatch"
import { motion } from 'framer-motion'
import { Eye, Flag } from 'lucide-react'

interface CompanyItemCardProps {
    onPressViewCompany: () => void,
    searchValue?: string,
    isLastItem?: boolean,
    onSelectCompany: () => void
    company: any,
}

export default function CompanyItemCard({
    company,
    isLastItem,
    searchValue = '',
    onPressViewCompany,
    onSelectCompany
}: CompanyItemCardProps) {

    const country = company.country

    return (
        <li>
            <motion.div
                className={`flex gap-2 relative hover:bg-slate-100 group items-center justify-between p-3 cursor-pointer ${!isLastItem ? 'border-b' : ''}`}
                onClick={onSelectCompany}
                whileTap={{ scale: 0.99 }}
            >
                <div className="flex space-x-2.5">
                    <Avatar className="flex h-8 w-8 mt-0.5 border border-gray-300 overflow-hidden items-center justify-center">
                        {(country || company.logo) ? (
                            <img
                                className='w-full h-full object-cover'
                                src={company.logo || `https://flagcdn.com/w40/${country.toLowerCase()}.png`}
                                alt="company-logo-or-flag"
                            />
                        ) : (
                            <Flag size={18} />
                        )}
                    </Avatar>
                    <div>
                        <h4 className="font-medium text-gray-800 text-[15px]">
                            {highlightMatch(company.name || company.displayName, searchValue, (text) => {
                                return <span className="bg-yellow-200 text-black">{text}</span>
                            })}
                        </h4>
                        <p className="text-gray-600 text-[12px]">
                            {highlightMatch(company.countryName, searchValue, (text) => {
                                return <span className="bg-yellow-200 text-black">{text}</span>
                            })}
                        </p>
                    </div>
                </div>
                <ButtonWithTooltip
                    size={'icon'}
                    type="button"
                    variant="outline"
                    tooltip="Ver empresa"
                    className="absolute top-0 bottom-0 my-auto w-7 h-7 right-3"
                    onClick={(ev) => {
                        ev.stopPropagation();
                        onPressViewCompany()
                    }}
                >
                    <Eye size={16} />
                </ButtonWithTooltip>
            </motion.div>
        </li>
    )
}