

import { getSaleBubbleTemplates } from '@/helpers/crm';
import { replaceObjectValues } from '@/helpers/replaceObjectValues';
import { SaleInteraction } from '@/interfaces/crm/contact';
import { SaleBubbleTemplate } from '@/interfaces/Interface';
import { useCallback, useMemo } from 'react';
import CancellationView from './CancellationView';
import MultilineView from './MultilineView';
import SimpleSaleView from './SimpleSaleView';
import StateChangeView from './StateChangeView';

interface SaleBubbleProps { interaction: SaleInteraction }

export default function SaleBubble(props: SaleBubbleProps) {
    const { interaction } = props;
    const sale = interaction?.content?.sale || {};

    const saleBubbleTemplates = useMemo(() => getSaleBubbleTemplates(), []);
    const defaultSaleBubbleTemplate = saleBubbleTemplates.find((template) => {
        return template.default && ((sale.saleStructureType == 'simple' || !sale.saleStructureType)
            ? template.structureType == 'simple'
            : template.structureType == 'multiline'
        )
    });

    const useSaleBubbleTemplate: SaleBubbleTemplate | null = useMemo(() => {
        let useTemplate: SaleBubbleTemplate | null = null;

        if (sale?.displayTemplate) {
            useTemplate = saleBubbleTemplates.find((template) => {
                return template.code == sale.displayTemplate;
            }) as SaleBubbleTemplate;
        };

        if (!useTemplate && defaultSaleBubbleTemplate) {
            useTemplate = defaultSaleBubbleTemplate;
        }

        return useTemplate;
    }, [saleBubbleTemplates, defaultSaleBubbleTemplate]);

    const replaceSaleProperties = useCallback((text: string) => {
        return replaceObjectValues(text, {
            sale: {
                additionalData: sale.additionalData ?? {},
                ticket_sale_id: sale.ticket_sale_id,
                ticket_id: sale.ticket_id,
                category: sale.category,
                product: sale.product,
                type: sale.type,
                cancel_reason: sale.cancel_reason,
                city: sale.city,
                date_sale: sale.date_sale,
                date: sale.date,
                gov_id: sale.gov_id,
                status: sale.status
            }
        })
    }, [sale])

    const saleTitle = useMemo(() => {
        if (useSaleBubbleTemplate && useSaleBubbleTemplate.saleTitle) {
            return replaceSaleProperties(useSaleBubbleTemplate.saleTitle)
        };

        return sale?.ticket_id || ''
    }, [useSaleBubbleTemplate, sale])

    const bubbleTitle = useMemo(() => {
        if (useSaleBubbleTemplate && useSaleBubbleTemplate.bubbleTitle) {
            return replaceSaleProperties(useSaleBubbleTemplate.bubbleTitle)
        };
    }, [useSaleBubbleTemplate, sale])

    console.log('HOLAAAAAAAAAAAAAAAAAAAAAAAAAaa MUNDO', bubbleTitle)

    const type = interaction.content.type;

    if (type == 'cancellation') {
        return <CancellationView interaction={interaction} />
    }

    if (type == 'state-change') {
        return <StateChangeView interaction={interaction} />
    }

    if (useSaleBubbleTemplate?.structureType == 'multiline' || sale.saleStructureType == 'multiline') {
        return <MultilineView
            template={useSaleBubbleTemplate}
            interaction={interaction}
            bubbleTitle={bubbleTitle}
            saleTitle={saleTitle}
        />
    }

    return <SimpleSaleView
        template={useSaleBubbleTemplate}
        interaction={interaction}
        bubbleTitle={bubbleTitle}
        saleTitle={saleTitle}
    />
}