import { getCompany } from "@/actions/auth";
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@/components/ui/accordion";
import { Button } from '@/components/ui/button';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import _ from "lodash";
import { Variable } from "lucide-react";
import { getContactVariables, getGroupVariables, getUserVariables } from "./helpers/constants/variables";


export type VariableType = {
    label: string,
    variables: {
        name: string,
        code: string
    }[]
}
export interface IVariablesProps {
    addValue: (variable: string) => void,
    withKeys?: boolean,
    trigger?: JSX.Element
    classNameTrigger?: string
    variables?: VariableType[]
    classNameIcon?: string
    variant?: "default" | "destructive" | "outline" | "outline-primary" | "outline-destructive" | "secondary" | "ghost" | "link" | "california" | "transparent" | "rounded"
}
export const defaultVariablesOptions = [
    {
        label: 'Contacto',
        variables: getContactVariables(_.get(getCompany(), 'additionalDataFields', []).filter((field) => field.active)
            .map((field) => {
                return {
                    name: field.name,
                    code: `contact.additionalData.${field.code}`
                }
            }))
    },
    {
        label: 'Grupo',
        variables: getGroupVariables(),
    },
    {
        label: 'Usuario',
        variables: getUserVariables(_.get(getCompany(), 'additionalUserFields', [])
            .filter((field) => field.active)
            .map((field) => {
                return {
                    name: field.name,
                    code: `user.additionalData.${field.key}`
                }
            }))
    }
]

export const VariablesMenu = ({
    addValue,
    withKeys = false,
    trigger,
    classNameTrigger,
    classNameIcon = '',
    variables = defaultVariablesOptions,
    variant = 'ghost',
}: IVariablesProps) => {

    const handleAddVariable = (variable: string) => {
        addValue(withKeys ? `{{${variable}}}` : variable)
    }

    return (
        <Popover modal={true}>
            <PopoverTrigger asChild>
                {
                    trigger || <Button
                        variant={variant}
                        className={cn("text-[12px] h-8", classNameTrigger)}
                    >
                        <Variable size={14} className={cn('mr-1 shrink-0', classNameIcon)} /> Variable
                    </Button>
                }
            </PopoverTrigger>
            <PopoverContent className="flex flex-col w-60 p-0 px-2.5" side="right">
                <Accordion type="single" collapsible className="w-full">
                    {
                        variables.map(({ label, variables }, inx) => (
                            <AccordionItem
                                className={`border-none`}
                                value={`item-${inx}`}
                                key={`item-${inx}`}
                            >
                                <AccordionTrigger className="text-[14px] py-3">
                                    {label}
                                </AccordionTrigger>
                                <AccordionContent className="max-h-[200px] overflow-y-auto">
                                    <div className="flex flex-col mb-2">
                                        {
                                            variables.map(({ code, name }) => (
                                                <ButtonVariableItem
                                                    onClick={() => handleAddVariable(code)}
                                                    label={name}
                                                    key={code}
                                                />
                                            ))
                                        }
                                    </div>
                                </AccordionContent>
                            </AccordionItem>
                        ))
                    }
                </Accordion>
            </PopoverContent>
        </Popover>
    )
}

export function ButtonVariableItem({ onClick, label }: { onClick: () => void, label: string }) {
    return <Button
        type="button"
        variant="ghost"
        size={'xs'}
        animateClick
        tabIndex={-1}
        className="gap-1 hover:text-primary justify-start"
        onClick={onClick}>
        <span className="max-w-[100%] relative truncate font-normal">
            {label}
        </span>
    </Button>
}