import { SaleInteraction } from "@/interfaces/crm/contact";
import { SaleBubbleTemplate } from "@/interfaces/Interface";
import dayjs from "dayjs";
import es from 'dayjs/locale/es';
import { useMemo, useState } from "react";
import SaleBubbleLayout from "./SaleBubbleLayout";
dayjs.locale(es);

interface SimpleSaleViewProps {
    interaction: SaleInteraction,
    template?: SaleBubbleTemplate | null,
    saleTitle: string,
    bubbleTitle?: string
}

export const fieldsLabels = new Map([
    ['date', { es: 'Fecha de ingreso', type: 'date' }],
    ['date_sale', { es: 'Fecha de venta', type: 'date' }],
    ['name', { es: 'Nombre' }],
    ['gov_id', { es: 'Cedula' }],
    ['status', { es: 'Estado' }],
    ['product', { es: 'Producto' }],
    ['category', { es: 'Categoría' }],
    ['value', { es: 'Precio' }]
]);

export const fields = Array.from(fieldsLabels.keys());

export default function SimpleSaleView({ interaction, template, saleTitle, bubbleTitle = "Venta registrada" }: SimpleSaleViewProps) {
    const [fieldsToShow, setFieldsToShow] = useState<number>(3);

    const { content, createdAt } = interaction;
    const sale = content?.sale || {};
    const hiddenFields = new Set(template?.hiddenFields || []);

    const fieldsWithValues = useMemo(() => {
        return fields.map((field) => {
            if (hiddenFields.has(field)) return null;

            const { es, type } = fieldsLabels.get(field) || {};

            const value = (() => {
                const fieldValue = sale[field];
                if (!fieldValue) return null;
                if (type == 'date') return dayjs(fieldValue).format('MMM D, YYYY h:mm A');
                return fieldValue;
            })();

            if (!value) return null;

            return {
                label: { es },
                value
            }
        }).filter(field => field)
    }, [sale])

    const onShowMoreFields = () => {
        setFieldsToShow(fieldsToShow == fieldsWithValues.length ? 3 : fieldsWithValues.length);
    }

    return (
        <SaleBubbleLayout createdAt={createdAt} title={bubbleTitle}>
            <div className="flex gap-4">
                {
                    (sale.productImage) && (
                        <div className="w-[75px] relative overflow-hidden h-[75px] rounded-sm bg-gray-100">
                            <img src={sale.productImage} className='w-full h-full object-cover' />
                        </div>
                    )
                }
                <div>
                    <h3 className='text-[16px] mb-1 font-medium text-gray-700'>
                        {saleTitle}
                    </h3>
                    <ul className="flex flex-col gap-[5px]">
                        {
                            fieldsWithValues.slice(0, fieldsToShow).map((field, index) => {
                                const { label, value } = field || {};

                                return (
                                    <li className="flex items-center gap-2" key={index}>
                                        <p className="font-medium text-[13.5px] text-gray-600">
                                            {label?.es || ''}
                                        </p>
                                        <p className="text-[13px] capitalize text-gray-500  font-medium">
                                            {value}
                                        </p>
                                    </li>
                                )
                            })
                        }
                        {
                            (fieldsWithValues.length > 3) && (
                                <button
                                    onClick={onShowMoreFields}
                                    className="text-primary w-max h-5 font-medium text-[12px]"
                                >
                                    {
                                        (fieldsToShow == fieldsWithValues.length)
                                            ? 'Mostrar menos'
                                            : 'Mostrar más'
                                    }
                                </button>
                            )
                        }
                    </ul>
                </div>
            </div>
        </SaleBubbleLayout>
    )
}