import { AgentOrNull } from "@/interfaces/Interface"
import { PhoneCall as PhoneInterface } from "@/interfaces/crm/contact"
import { LocalStatus } from "@/redux/libs/ts/interfaces"
import { handleSetLocalStatus } from "@/redux/slices/crm"
import moment from "moment"
import { useMemo } from "react"
import { useDispatch } from "react-redux"
import { createInteraction } from "../Prospect/libs/helpers/interaction"
import InteractiveMessageCard from "./InteractiveMessageCard"
import { IAgentData } from "./TransferBubble"
import { phoneState } from "./libs/constants"
import { Clock } from "lucide-react"

moment.locale("es")

type TStates = "calling" | "done" | "cancel" | "voicemail"

interface IPhoneBubble {
  interaction: PhoneInterface
  profile: string | null
  hiddenProfile?: boolean,
  customStyle?: string | null
  agent?: AgentOrNull | IAgentData
  notResponseCall?: number
}

const getTextDescriptionPhone = (state: TStates, notResponse?: number) => {
  const typesObjects = {
    done: "Llamada contestada",
    calling: "En llamada",
    cancel: "Llamada no contestada",
    voicemail: "Correo de voz",
    greaterThanNine: "+9 llamadas sin respuesta",
  }

  if (
    (state === "cancel" || state === "voicemail") &&
    notResponse != undefined &&
    notResponse > 2
  ) {
    if (notResponse > 9) return typesObjects.greaterThanNine
    return `${notResponse} llamadas sin respuesta`
  }

  return typesObjects[state]
}

const PhoneBubble = ({
  interaction,
  hiddenProfile,
  notResponseCall,
  profile,
}: IPhoneBubble) => {
  const { content, agent, localStatus, createdAt, via } = interaction
  const { state, origin, callDuration } = content
  const date = useMemo(() => new Date(createdAt), [createdAt])

  const dispatch = useDispatch()

  const handleRetry = () => {
    const { _id: interactionId, contact, content } = interaction || {}
    const { phone, state } = content || {}

    if (interactionId) {
      dispatch(handleSetLocalStatus({ localId: interactionId, status: LocalStatus.LOADING }))
      createInteraction({
        dispatch,
        isRetry: true,
        type: state === 'calling' ? 'phonecall' : 'closeCallPhone',
        data: {
          localId: interactionId,
          id: contact,
          interactionid: interaction._id,
          state,
          phone: phone
        },
      });
    }
  }

  return (
    <>
      <InteractiveMessageCard
        agent={agent}
        profile={profile}
        retryFn={handleRetry}
        hiddenProfile={hiddenProfile}
        origin={origin}
        status={localStatus}
        messageError="No se pudo realizar la llamada."
        className="bg-white min-w-[20%] shadow-bubble-phone"
      >
        <div className="px-4 py-3">
          <div className="bg-transparent  rounded-none rounded-tl-[3px] rounded-b-[10px] rounded-tr-[10px] flex justify-between flex-grow gap-10 items-center border-none shadow-none">
            <div>
              <div
                className="flex items-center shrink-0 gap-2"
                style={{
                  color: phoneState[state]?.color || phoneState.default.color,
                }}
              >
                <span className="shrink-0">
                  {phoneState[state]?.icon || phoneState.default.icon}
                </span>
                <h4 className="sm:text-[14px] text-[13px] font-medium">
                  {getTextDescriptionPhone(state, notResponseCall)}
                </h4>
              </div>
            </div>
            <TimeOfBubbles date={date} />
          </div>
          {(!!callDuration && callDuration > 0) && (
              <div className="flex items-center gap-2 mt-2 text-slate-500">
                <Clock size={16} />
                <span className="text-[12px]">
                  {callDuration > (60 * 60) ? moment.utc(callDuration * 1000).format("H:mm:ss") : moment.utc(callDuration * 1000).format("m:ss")} 
                </span>
              </div>
          )}
        </div>

      </InteractiveMessageCard>
    </>
  )
}

export function TimeOfBubbles({ date }) {
  return (
    <span className="text-[12px] text-[#6B7280]">
      {moment(date).format("LT")}
    </span>
  )
}

export default PhoneBubble
