import { NotificationShipmentFormSchemaType } from "@/components/admin/notifications/shipment/Container";
import { GeneralShipmentStats } from "@/components/admin/notifications/shipment/hooks/useGetGeneralStatistics";
import { NotifcationWithStats } from "@/components/admin/notifications/shipment/hooks/useGetStatsByNotifications";
import { fetcher } from "@/helpers/fetcher";
import { formatSearchParams } from "@/helpers/formatSearchParams";
import { ActionToTrack, NotificationSettingInterface, NotificationShipment, ShipmentsResult, UserShipment } from "@/interfaces/Interface";


class UserNotificationService {

    public createShipmentNotification(body: { name: string }) {
        return fetcher<{ success: boolean, notificationShipment: NotificationShipment }>({
            url: '/notification/admin/shipment',
            defaultContentType: true,
            method: 'POST',
            body
        })
    }

    public getShipmentNotificationById(id: string) {
        return fetcher<{ success: boolean, notificationShipment: NotificationShipment }>({
            url: `/notification/admin/shipment/${id}`
        })
    }

    public updateShipmentNotification(id: string, body: NotificationShipmentFormSchemaType) {
        return fetcher<{ success: boolean, shipment: NotificationShipment }>({
            url: `/notification/admin/shipment/${id}`,
            defaultContentType: true,
            method: 'PATCH',
            body
        })
    };

    public getUsersOfShipment(id: string, params: Record<string, any>) {
        const searchParams = formatSearchParams(params);
        return fetcher<{ success: boolean, countDocuments: number, users: UserShipment[] }>({
            url: `/notification/admin/shipment/${id}/users?${searchParams}`
        })
    };

    public sendNotificationShipment(id: string) {
        return fetcher<{ success: boolean }>({
            url: `/notification/admin/shipment/${id}/send`,
            method: 'POST'
        })
    }

    public getMyNotifications({
        unopened = 0,
        fields = ['countDocuments', 'notifications'],
        limit = 50,
        page = 1
    }: { unopened?: 0 | 1, fields?: ('countDocuments' | 'notifications')[], limit?: number, page?: number }) {
        const searchParams = formatSearchParams({ platform: 'web', unopened, fields, limit, page });

        return fetcher<{
            success: boolean,
            countDocuments?: number,
            notifications?: NotificationSettingInterface[]
        }>({
            url: `/notification/user?${searchParams}`,
            method: 'GET'
        })
    }

    public trackAction(id: string, actions: ActionToTrack[]) {
        return fetcher<{ success: boolean, }>({
            url: `/notification/user/${id}/track?action=${actions.join(',')}`,
            method: 'PUT'
        })
    }

    public getGeneralStatsOfShipment(id: string) {
        return fetcher<{ success: boolean, statistics: GeneralShipmentStats }>({
            url: `/notification/admin/shipment/${id}/statistics/general`,
            method: 'GET'
        })
    }

    public getStatsByNotifications(id: string) {
        return fetcher<{ success: boolean, notifications: NotifcationWithStats[] }>({
            url: `/notification/admin/shipment/${id}/statistics/byNotification`,
            method: 'GET'
        })
    }

    public getShipments() {
        return fetcher<{ success: boolean, shipments: ShipmentsResult[] }>({
            url: `/notification/admin/shipments`,
            method: 'GET'
        })
    }

    public getTemplates() {
        return fetcher<{ success: boolean, templates: NotificationSettingInterface[] }>({
            url: `/notification/admin/templates`,
            method: 'GET'
        })
    }

    public deleteTemplate(id: string) {
        return fetcher<{ success: boolean }>({
            url: `/notification/admin/templates/${id}`,
            method: 'DELETE'
        })
    }

    public deleteShipment(id: string) {
        return fetcher<{ success: boolean }>({
            url: `/notification/admin/shipment/${id}`,
            method: 'DELETE'
        })
    }
};

const userNotification = new UserNotificationService();
export default userNotification;