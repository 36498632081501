import { doneSuggestion } from "@/actions/crm/contact"
import { LoadingButton } from "@/components/buttons/loading-button"
import { toastActionError } from "@/components/comercial/comercialManagment/comissions/CommisionModal"
import {
    Card,
    CardDescription,
    CardHeader,
    CardTitle
} from "@/components/ui/card"
import { useToggle } from "@/hooks/useToggle"
import { useMutation } from "@tanstack/react-query"
import { AlarmClock } from "lucide-react"
import moment from "moment"
import { useEffect, useState } from "react"
import { InteractionSuggesstion } from '../../../interfaces/Interface'
import ModalReminder from "../BottomActions/Reminder/ModalReminder"

interface SuggesstionsListProps {
    suggestions?: InteractionSuggesstion[],
    interactionId: string,
    isAgent?: boolean,
    contactId: string
}

export default function SuggesstionsList({
    suggestions = [],
    interactionId,
    contactId,
    isAgent
}: SuggesstionsListProps) {
    const [suggesstionsList, setSuggesstionsList] = useState<InteractionSuggesstion[]>(suggestions);
    const [suggesstionIndex, setSuggesstionIndex] = useState<number | undefined>(undefined)
    const [openReminderModal, toggleReminderModal] = useToggle<boolean>();

    const currentDate = new Date();

    const handleDoneSuggesstion = (index: number, done: boolean) => {
        const updatedSuggesstions = [...suggesstionsList];

        updatedSuggesstions[index] = {
            ...updatedSuggesstions[index],
            done
        };

        return updatedSuggesstions;
    }

    const handleSuccessDone = () => {
        setSuggesstionsList(handleDoneSuggesstion(suggesstionIndex as number, true));
        setSuggesstionIndex(undefined);
        toggleReminderModal(false);
    };

    const { mutate: mutateDone, isPending } = useMutation({
        mutationFn: ({ index }: { index: number }) => doneSuggestion(contactId, interactionId, index),
        onError: () => {
            handleDoneSuggesstion(suggesstionIndex as number, false);
            toastActionError();
        },
        onSuccess: handleSuccessDone
    })

    const handleReminderSuccess = () => {
        toggleReminderModal(false);
        mutateDone({ index: suggesstionIndex as number });
        handleDoneSuggesstion(suggesstionIndex as number, true);
    }

    const handleOpenReminderModal = (reminderDate: string, index: number) => {
        if (!reminderDate) return;
        toggleReminderModal();
        setSuggesstionIndex(index)
    }

    useEffect(() => setSuggesstionsList(suggestions), [suggestions])
    if (suggestions.length == 0) return null;

    return (
        <>
            {
                openReminderModal && (
                    <ModalReminder
                        open={openReminderModal && contactId}
                        onCLose={toggleReminderModal}
                        handleSuccess={handleReminderSuccess}
                        defaultValues={typeof suggesstionIndex == 'number' ? {
                            date: suggestions[suggesstionIndex]?.content?.reminderDate || new Date(),
                            step: 1
                        } : undefined}
                    />
                )
            }
            <ul className={`flex flex-col gap-2 mt-4 ${isAgent ? 'mr-10' : 'ml-10'}`}>
                {
                    suggesstionsList.map(({ content, done, removed }, index) => {
                        if (removed || done) return null;
                        if (content?.reminderDate && new Date(content.reminderDate) <= currentDate) return null;

                        return <li key={index}>
                            <Card className="md:w-[300px] w-max px-4 py-2.5 bg-gray-50">
                                <CardHeader className="p-0">
                                    <div className="w-full flex flex-col md:flex-row items-end gap-2 md:gap-4 md:items-center md:justify-between">
                                        <div className="flex items-center gap-2.5">
                                            <AlarmClock className="text-gray-400 text-[23px] md:text-[26px]" />
                                            <div>
                                                <CardTitle className="md:text-[13px] text-[12px] text-gray-400 mb-0.5" >
                                                    Recordatorio sugerido
                                                </CardTitle>
                                                <CardDescription className="text-[11px] md:text-[12px] text-[#464545]">
                                                    {moment(content?.reminderDate).calendar().replace(/^\w/, (c) => c.toUpperCase())}
                                                </CardDescription>
                                            </div>
                                        </div>
                                        <LoadingButton
                                            loading={isPending}
                                            className="bg-gray-400 hover:bg-gray-500 h-6 md:h-7 px-2 md:px-3 text-[12px] md:text-[12.7px]"
                                            onClick={() => handleOpenReminderModal(content?.reminderDate || '', index)}
                                        >
                                            Recordar
                                        </LoadingButton>
                                    </div>
                                </CardHeader>
                            </Card>
                        </li>
                    })
                }
            </ul>
        </>
    )
}