import { ReactNode } from "react";

export const highlightMatch = (
    text: string,
    searchValue: string,
    render?: (text: string) => ReactNode
) => {
    if (!searchValue) return text;

    const escapedSearchValue = searchValue.trim().replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
    const regex = new RegExp(`(${escapedSearchValue})`, 'gi');

    return text.split(regex).map((part, index) =>
        part.toLowerCase() === searchValue.trim().toLowerCase() ? render
            ? render(part)
            : (
                <span key={index} className="font-bold text-black">
                    {part}
                </span>
            ) : (
            part
        )
    );
};
