import { getDocumentUrl } from "@/actions/aws/documents";
import { FileCharacteristics } from "@/components/crm/ChatInput/UploadImageModal";
import { getBase64 } from "@/helpers";
import axios from "axios";

export type UploadFileStatus = 'loading' | 'error' | 'idle' | 'success'
export type FileConstructor = (FileCharacteristics & {
    status: UploadFileStatus,
    mediaType: string,
    progress?: number,
    isFirstPreview?: boolean
})

export interface IObjectFile {
    path: string,
    type: string,
    name: string,
}

export const uploadFileToS3 = async (file: File, ops: {
    disposition?: 'inline' | 'attachment',
    expires?: number,
    fnGetPreview?: (value: FileConstructor) => void, returnKey?: boolean
} = {}) => {
    const { type, name, size } = file
    const { fnGetPreview, disposition, expires } = ops || {}

    const fileConstructor: FileConstructor = {
        path: '',
        type,
        mediaType: categorizeFile(type),
        name,
        size,
        status: 'loading',
        progress: 0
    };

    fnGetPreview && fnGetPreview(fileConstructor);

    try {
        const path = await getBase64(file);
        fileConstructor.path = path;

        fnGetPreview && fnGetPreview({ ...fileConstructor, isFirstPreview: true });

        let pressignedURL = await getDocumentUrl({ name, mimeType: fileConstructor.type, disposition, expires });
        const url = pressignedURL.url
        const key = pressignedURL.key

        if (!url) return;

        await axios.put(url, file, {
            headers: {
                'Content-Type': 'application/octet-stream',
                'Access-Control-Allow-Origin': '*',
            },
            onUploadProgress: (progressEvent) => {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                fileConstructor.progress = progress;
                fnGetPreview && fnGetPreview(fileConstructor);
            }
        });

        const splittedUrl = url.split('?')[0];

        fnGetPreview && fnGetPreview({
            ...fileConstructor,
            path: splittedUrl,
            status: 'success'
        });

        if (ops.returnKey) {
            return {
                url: splittedUrl,
                key
            }
        }

        return splittedUrl;
    } catch (error) {
        throw error
    }
};

interface Options {
    disposition?: 'inline' | 'attachment'
    onError?: (error: any, file: IObjectFile, index: number) => void,
    getPreview?: (file: {
        index: number,
        file: IObjectFile
    }) => void
}

export const uploadFileToS3ByObject = async (
    files: IObjectFile[] | IObjectFile,
    options: Options = {},
) => {
    const filesToArray = [files].flat()
    const filesUpdated: IObjectFile[] = []

    const { getPreview, disposition, onError } = options || {};

    for (let index = 0; index < filesToArray.length; index++) {
        const originalFile = filesToArray[index];
        const { path, type, name, ...rest } = originalFile;

        if (path.startsWith('https')) {
            filesUpdated.push({
                path,
                type,
                name,
                ...rest
            });
            continue;
        }

        const realType = type || path.split(';')[0].split(':')[1];
        const pathToUint8Array = Uint8Array.from(atob(path.split(',')[1]), c => c.charCodeAt(0)).buffer;
        const blob = new Blob([pathToUint8Array], { type: realType });
        const file = new File([blob], name, { type: realType });

        let valueToSave = {
            ...rest,
            type: realType,
            name,
            path
        };

        let error = false;

        try {
            const url = await uploadFileToS3(file, {
                disposition,
                fnGetPreview: async (uploadedFile) => {
                    if (getPreview) {
                        await getPreview({
                            index,
                            file: uploadedFile
                        })
                    }
                },
            });

            valueToSave['path'] = url as string;
        } catch (error) {
            if (onError) onError(error, originalFile, index)
            error = true;
        }

        if (!error) filesUpdated.push(valueToSave);
    }

    return filesUpdated
}

const categorizeFile = (type: string): FileType => {
    if (type.startsWith('image/')) {
        return 'image';
    } else if (type.startsWith('video/')) {
        return 'video';
    } else if (type.startsWith('audio/')) {
        return 'audio';
    } else {
        return 'file';
    }
}

export const FileTypes = {
    Image: 'image',
    Video: 'video',
    Audio: 'audio',
    File: 'file'
} as const

export type FileType = typeof FileTypes[keyof typeof FileTypes]

export async function downloadAudioAsBlob(audioUrl: string) {
    try {
        const response = await fetch(audioUrl);

        if (!response.ok) {
            throw new Error(`Error al descargar el audio: ${response.statusText}`);
        }

        const audioBlob = await response.blob();
        return audioBlob;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}