import { fetcher } from "@/helpers/fetcher"

class Contact {
    private url: string

    constructor() {
        this.url = '/crm/contact'
    }

    public createReminder({ id, data, localId, socketIgnore }: {
        id: string,
        localId?: string,
        socketIgnore?: string,
        data: {
            action?: string,
            comment?: string,
            reminderDate: string
        }
    }) {
        const params = new URLSearchParams()
        params.append('id', id)
        if (localId) params.append('localId', localId)
        if (socketIgnore) params.append('socketIgnore', socketIgnore)

        return fetcher({
            url: `${this.url}/reminder?${params.toString()}`,
            method: "POST",
            defaultContentType: true,
            body: {
                via: "web",
                data
            }
        })
    }
}

export default new Contact()