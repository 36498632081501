import { isAuth } from '@/actions/auth';
import { Feature, PersonalFeature, ROLES, getFeatureCompany, getPersonalFeature, isFeatureEnabled, isPersonalFeatureEnables, isTheRolEnabled } from '@/helpers/featureFlagHelpers';
import _ from 'lodash';
import "moment/locale/es";
import { useRouter } from 'next/router';
import { ReactNode, useMemo } from 'react';
import { useSelector } from "react-redux";
import { LIST_OF_ROUTES, SLUG_HEADER } from '../constants/list-of-routes';

interface Item {
    label: string
    href?: string
    isActive?: boolean
    icon: ReactNode
    onAction?: () => void
    slug?: string
    condition?: boolean,
}

export default function useGetLink({ isCollapsedMenu, group }) {
    const router = useRouter()
    const manager = useSelector((state: any) => state.header.manager)
    const inboxCount = useSelector((state: any) => state.header.inboxCount)
    const sharedInboxState = useSelector((state: any) => state.header.sharedInboxState);

    const {
        ADMINISTRATION,
        CALENDAR,
        COMPANIES,
        GOALS,
        MAP,
        NPS,
        CAMPAIGNS,
        PRESALE,
        REPORTS,
        ACTIONS_HISTORY,
        REQUESTS,
        SALES,
        // TRAINING,
        COMPANY,
    } = LIST_OF_ROUTES

    const itemsHome: Item[] = useMemo(() => [
        {
            ...REPORTS,
            slug: SLUG_HEADER.REPORTS,
            condition: true,
        },
        // {
        //     ...TRAINING,
        //     slug: SLUG_HEADER.TRAINING,
        //     condition: !!manager
        // }
    ], [isCollapsedMenu, router, manager])

    const itemsBackoffice: Item[] = useMemo(() => [
        {
            ...REQUESTS,
            slug: SLUG_HEADER.REQUESTSALE,
            condition: (isFeatureEnabled(Feature.ENABLE_REQUEST_SALE) &&
                !getPersonalFeature<boolean>(PersonalFeature.DISABLE_REQUEST_SALE, false))
        },
        {
            ...PRESALE,
            slug: SLUG_HEADER.PRESALE,
            condition: !!group?.sale?.presale,
        },
        {
            ...GOALS,
            slug: SLUG_HEADER.GOAL,
            condition: !isFeatureEnabled(Feature.DISABLE_GOAL_FEATURE)
        },
        {
            ...NPS,
            slug: SLUG_HEADER.NET_PROMOTER_SCORE,
            condition: isTheRolEnabled('project_manager') && isFeatureEnabled(Feature.ENABLE_NET_PROMOTER_SCORE)
        }
    ], [group, router])

    const itemsContacts: Item[] = useMemo(() => {
        const hideCompanyGroupsPanelToRoles: string[] = _.get(getFeatureCompany(Feature.HIDE_COMPANY_GROUPS_PANEL, { roles: [] }), 'roles');
        const userRoles = _.get(isAuth(), 'roles', []);

        return [
            {
                ...MAP,
                slug: SLUG_HEADER.MAP,
                condition: isFeatureEnabled(Feature.LOCATION_FIELD)
            },
            {
                ...CALENDAR,
                condition: true,
                slug: SLUG_HEADER.CALENDAR
            },
            {
                ...ACTIONS_HISTORY,
                slug: SLUG_HEADER.ACTIONS_HISTORY,
                condition: isTheRolEnabled('super') || isTheRolEnabled('comercial')
            },
            {
                ...CAMPAIGNS,
                slug: SLUG_HEADER.CAMPAIGNS,
                condition: isFeatureEnabled(Feature.ENABLE_CAMPAIGNS) && isTheRolEnabled('project_manager')
            },
            {
                ...COMPANIES,
                slug: SLUG_HEADER.COMPANIES,
                condition: isFeatureEnabled(Feature.ENABLE_BUSINESS_SALES_MODEL) && !userRoles.some((role: string) => {
                    return hideCompanyGroupsPanelToRoles.includes(role)
                })
            },
        ]
    }, [isFeatureEnabled, isAuth()])

    const inboxItems = useMemo(() => {
        return [
            {
                ...LIST_OF_ROUTES.INBOX,
                condition: isFeatureEnabled(Feature.INBOX) || isPersonalFeatureEnables(PersonalFeature.PERSONAL_INBOX),
                slug: SLUG_HEADER.UNASSIGNED,
                notification: inboxCount
            },
            {
                ...LIST_OF_ROUTES.CONVERSATIONS,
                slug: SLUG_HEADER.CONVERSATIONS,
                condition: true
            },
            {
                ...LIST_OF_ROUTES.SHARED_CONTACT_INBOX,
                slug: SLUG_HEADER.SHARED_CONTACT_INBOX,
                condition: !!sharedInboxState?.active,
                pingAnimate: !!sharedInboxState?.activeNow
            }
        ]
    }, [inboxCount, sharedInboxState])

    const restOptionHeader: Item[] = useMemo(() => [
        {
            ...COMPANY,
            slug: SLUG_HEADER.COMPANY,
            icon: COMPANY.icon({ group }),
            condition: isTheRolEnabled([ROLES.COMERCIAL, ROLES.SUPER, ROLES.USER_MANAGER])
        },
        {
            ...ADMINISTRATION,
            slug: SLUG_HEADER.GROUPS,
            condition: isTheRolEnabled(ROLES.ADMIN)
        }
    ], [router, manager, group])

    return {
        restOptionHeader,
        inboxItems,
        itemsBackoffice,
        itemsHome,
        itemsContacts
    }
}
