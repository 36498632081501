import { useMutation } from '@tanstack/react-query';
import debounce from 'lodash/debounce';
import { useCallback, useState } from 'react';
import placesService from '../actions/google/places';
import { GooglePlaceResult } from '../interfaces/Interface';

interface UsePlacesServiceParams {
    debounce?: number,
    onError?: () => void
};

export default function usePlacesService({
    debounce: debounceTime = 10,
    onError
}: UsePlacesServiceParams) {
    const [placePredictions, setPlacePredictions] = useState<GooglePlaceResult['places']>([]);

    const { mutate, isPending: isPlacePredictionsLoading } = useMutation({
        mutationFn: ({ search }: { search: string }) => placesService.getPlacePredictions(search),
        onSuccess: (data) => setPlacePredictions(data?.places || []),
        onError: onError
    });

    const getPlacePredictions = useCallback(
        debounce(({ input }: { input: string }) => {

            if (!input.trim()) return setPlacePredictions([]);
            mutate({ search: input });

        }, debounceTime),
        [mutate, debounceTime]
    );

    return {
        isPlacePredictionsLoading,
        placePredictions,
        getPlacePredictions
    };
}
