import { isAuth, modifyUser } from "@/actions/auth";
import { savePersonalConfig } from "@/actions/config/personalconfig";
import { LoadingButton } from "@/components/buttons/loading-button";
import { toastActionError } from "@/components/comercial/comercialManagment/comissions/CommisionModal";
import { Button } from "@/components/ui/button";
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle
} from "@/components/ui/dialog";
import { ModalPropsBase } from '@/interfaces/Interface';
import { useMutation } from "@tanstack/react-query";
import _ from "lodash";
import { CircleCheckBig } from "lucide-react";
import { useEffect, useState } from "react";

interface PhoneCallOnAppModalProps extends ModalPropsBase {
    startCall: () => void
}

export default function PhoneCallOnAppModal({ open, onOpenChange, startCall }: PhoneCallOnAppModalProps) {
    const [step, setStep] = useState<0 | 1>(0);

    const isActivatedFeature = step == 1;

    useEffect(() => setStep(0), [open])

    const { mutate, isPending } = useMutation({
        mutationFn: () => savePersonalConfig({ callOnPhone: true }),
        onError: toastActionError,
        onSuccess: () => {
            const lastPersonalConfig = _.get(isAuth(), 'personalConfig', {});
            modifyUser('personalConfig', { ...lastPersonalConfig, callOnPhone: true })
            setStep(1);
        }
    });

    const startCallAndCloseModal = () => {
        startCall();
        onOpenChange(false);
    }

    const handleClickActivateFeature = () => {
        if (step == 1) return startCallAndCloseModal();
        mutate();
    }

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className="sm:max-w-[470px]" showCloseButton={false}>
                <DialogHeader>
                    <DialogTitle>
                        {
                            !isActivatedFeature
                                ? 'Redirigir llamadas a tu celular'
                                : 'Funcionalidad activada'
                        }
                    </DialogTitle>
                </DialogHeader>
                <div className="py-3 pb-4">
                    {
                        isActivatedFeature
                            ? <div className="w-full flex items-center mb-6 justify-center">
                                <CircleCheckBig size={140} className="text-primary" />
                            </div>
                            : <div className="w-full h-[270px] mb-3 relative overflow-hidden">
                                <img src="./phone-call-app-feature.webp" className="w-full h-full object-contain" />
                            </div>
                    }
                    <p className="text-gray-500 text-[14px]">
                        {
                            !isActivatedFeature
                                ? 'Activa esta opción para redirigir automáticamente las llamadas de tu navegador a la aplicación móvil.'
                                : 'Abre Ventia en tu celular y, desde la web, realiza una llamada para redirigirla a tu dispositivo móvil.'
                        }
                    </p>
                    {/* <p className="text-primary text-sm mt-2">
                        <a href="/" className="hover:underline">Mas información</a> - {" "}
                        <a href="/" className="hover:underline">Descargar aplicación móvil</a>
                    </p> */}
                </div>
                <DialogFooter>
                    {
                        !isActivatedFeature && (
                            <Button
                                type="button"
                                variant={'ghost'}
                                onClick={startCallAndCloseModal}
                                className="text-primary hover:text-primary"
                            >
                                Omitir
                            </Button>
                        )
                    }
                    <LoadingButton
                        type="button"
                        loading={isPending}
                        onClick={handleClickActivateFeature}
                    >
                        {
                            isActivatedFeature
                                ? 'Realizar llamada'
                                : 'Activar funcionalidad'
                        }
                    </LoadingButton>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}