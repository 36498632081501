import { z } from "zod";

export const sharedOptionSchema = z.object({
    backgroundColor: z.string(),
    color: z.string(),
    name: z.string().min(1, { message: 'El nombre de la opción es requerido.' }).max(200, {
        message: 'El nombre no debe de superar los 200 caracteres de longitud.'
    })
});

export const partialSharedOptionSchema = sharedOptionSchema.partial();