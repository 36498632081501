import { Badge } from "@/components/ui/badge";
import { SharedOption } from "@/interfaces/Interface";
import { XIcon } from "lucide-react";

interface ValueOptionItemProps {
    option: SharedOption,
    onDeleteOption: () => void
}

export default function ValueOptionItem({ option, onDeleteOption }: ValueOptionItemProps) {
    return (
        <Badge
            variant={"outline"}
            className="px-1.5"
        >
            <div
                className="w-[15px] h-[15px] border rounded-full shrink-0 mr-1.5"
                style={{ backgroundColor: option.backgroundColor }}
            />
            {option.name}
            <button
                type='button'
                className={'ml-1'}
                onClick={onDeleteOption}
                onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') onDeleteOption()
                }}
            >
                <XIcon className="h-3 w-3 text-muted-foreground hover:text-foreground" />
            </button>
        </Badge>
    )
}
