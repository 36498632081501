import { cn } from '@/lib/utils';
import { forwardRef, HTMLAttributes, ReactNode, useCallback, useEffect, useRef, useState } from 'react';

if (typeof window !== 'undefined' && !('IntersectionObserver' in window)) {
    import('intersection-observer');
}

type ContainerElement = keyof HTMLElementTagNameMap;

interface InfiniteScrollProps extends HTMLAttributes<HTMLDivElement> {
    as?: ContainerElement;
    classNameIntersection?: string,
    readonly children?: ReactNode,
    next?: null | (() => void),
    rootMargin?: string,
    hasMore?: boolean;
    endMessage?: ReactNode
    loader?: ReactNode;
    dataLength: number;
    className?: string;
    threshold?: number,
    disabled?: boolean,
    loading?: boolean
}

const InfiniteScroll = forwardRef<HTMLDivElement, InfiniteScrollProps>(({
    hasMore = true,
    next,
    children,
    loader,
    dataLength = 0,
    className = '',
    threshold = 1.0,
    classNameIntersection,
    disabled,
    rootMargin,
    loading,
    endMessage,
    as: Element = 'div',
    ...props
}, ref) => {
    const [lastDataLength, setLastDataLength] = useState(dataLength);
    const [isLoading, setIsLoading] = useState<boolean>(!!loading);
    const observer = useRef<IntersectionObserver | null>(null);
    const loadMoreRef = useRef<HTMLDivElement | null>(null);

    const disableInfiniteScroll = !hasMore || disabled || isLoading;

    useEffect(() => {
        if (typeof loading !== 'boolean') return;
        setIsLoading(loading)
    }, [loading]);

    const handleObserver = useCallback((entries: IntersectionObserverEntry[]) => {
        const target = entries[0];
        if (target.isIntersecting && !disableInfiniteScroll) {
            setIsLoading(true);
            next && next();
        }
    }, [isLoading, hasMore, next, disableInfiniteScroll]);

    useEffect(() => {
        if (dataLength > lastDataLength) {
            setIsLoading(false);
            setLastDataLength(dataLength);
        }
    }, [dataLength, lastDataLength]);

    useEffect(() => {
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(handleObserver, { threshold, rootMargin });
        if (loadMoreRef.current) observer.current.observe(loadMoreRef.current);
        return () => observer.current?.disconnect();
    }, [handleObserver]);

    const Component = Element as any;

    return (
        <Component
            className={cn('flex flex-col overflow-y-auto', className)}
            ref={ref}
            {...props}
        >
            {children}
            {!hasMore && endMessage}
            {(isLoading && loader) && loader}
            <div ref={loadMoreRef} className={cn('h-[2px] my-0', classNameIntersection)}></div>
        </Component>
    );
});

export default InfiniteScroll;
