import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import IconButton from '@mui/material/IconButton'
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import _, { get } from "lodash"
import moment from "moment"
import React, { useMemo, useState } from "react"
import { isAuth } from "../../../../actions/auth"
import FileUploadInput from "../FileUploadInput"
import UploadMultipleFileInput from "../UploadMultipleFileInput"
import SearchField from './SearchField'

const validateFlowEdit = (flow: string[]) => {
    if (get(isAuth(), 'config.useFlow', []).length == 0) return false
    let valid_flow = true
    get(isAuth(), 'config.useFlow', []).map((item) => {

        if (flow.includes(item)) valid_flow = false
    })
    return valid_flow
}


const handleOptionSelect = (option) => {
    let userField = _.get(option, 'userFieldFilter', {})
    let validOption = true
    if (isAuth().roles.includes('comercial')) return true
    Object.keys(userField).filter((fieldName) => fieldName != '_id').map((fieldName) => {
        if (userField[fieldName] && userField[fieldName].length > 0) {
            let valueUser = _.get(isAuth(), 'additionalData.' + fieldName, '')
            if (valueUser != userField[fieldName]) validOption = false
        }
    })
    return validOption
}

const ProgramableField = React.memo((props: any) => {
    return <ProgramableFieldTS {...props} />;
});

const ProgramableFieldTS = ({
    fieldData,
    onChange,
    value,
    disabledValue,
    requestData,
    handleChangeRequestSaleProperties
}) => {

    const disabled = useMemo(() => {
        return disabledValue || fieldData.editBackoffice && !_.get(isAuth(), 'roles', []).includes('comercial') || validateFlowEdit(get(fieldData, 'flow', []))
    }, [disabledValue, fieldData])


    switch (fieldData.type) {
        case 'string':
            let errorLength = fieldData.maxLength > 0 && value && (value + "").length > 0 && (fieldData.maxLength < (value + "").length || fieldData.minLength > (value + "").length)
            let errorREGEX = fieldData.regex && value && fieldData.regex.length > 0 && (value + "").length > 0 && !new RegExp(fieldData.regex).test(value)

            return <CustomTextInput
                title={fieldData.title}
                required={fieldData.required}
                error={errorLength || errorREGEX}
                helperText={errorLength ? `${fieldData.minLength} a ${fieldData.maxLength} caracteres` : errorREGEX ? 'El valor es inválido' : ''}
                value={value || ''}
                onChange={onChange}
                disabled={disabled}
            />

            break;
        case 'number':
            let errorLengthNumber = fieldData.maxLength > 0 && value && (value + "").length > 0 && (fieldData.maxLength < (value + "").length || fieldData.minLength > (value + "").length)
            return <TextField fullWidth autoComplete="off" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} error={errorLengthNumber} helperText={errorLengthNumber ? `${fieldData.minLength} a ${fieldData.maxLength} caracteres` : ''} label={fieldData.title} required={fieldData.required} value={value ?? ""} onChange={onChange} disabled={disabled} />
            break;
        case 'selectmultiple':
            return <Autocomplete
                disablePortal
                id="o-box-demo"
                value={value || ""}
                onChange={onChange}
                disabled={disabled}
                options={fieldData.options.filter(handleOptionSelect).map((o) => ({ id: o.key, label: o.title }))}
                renderInput={(params) => <TextField required={fieldData.required} autoComplete="off" {...params} label={fieldData.title} />}
            />
        case 'select':
            if (fieldData.autocompletable) {
                return (
                    <Autocomplete
                        disablePortal
                        id="o-box-demo"
                        value={value || ""}
                        onChange={(__, value) => {
                            handleChangeRequestSaleProperties({ [fieldData.field]: value?.id || undefined })
                        }}
                        disabled={disabled}
                        options={fieldData.options.filter(handleOptionSelect).map((o) => ({ id: o.key, label: o.title }))}
                        renderInput={(params) => <TextField required={fieldData.required} autoComplete="off" {...params} label={fieldData.title} />}
                    />
                )
            }

            return <FormControl fullWidth sx={{ minWidth: '200px' }} required={fieldData.required} disabled={disabled}>
                <InputLabel>{fieldData.title}</InputLabel>
                <Select
                    label={fieldData.title}
                    value={value || ""}
                    onChange={onChange}
                    required={fieldData.required}
                    autoComplete="off"
                >
                    {
                        fieldData.options.filter(handleOptionSelect).map((o) => (
                            <MenuItem value={o.key}>{o.title}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        case 'date':
            return <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    inputFormat="dd/MM/yyyy"
                    label={fieldData.title + (fieldData.required ? ' *' : '')}
                    value={value ? moment(value, 'YYYY/MM/DD').toDate() : null}
                    onChange={onChange}
                    disabled={disabled}
                    renderInput={(params) => <TextField fullWidth autoComplete="off" {...params} />}
                />
            </LocalizationProvider>
            break;
        case 'datetime':
            break;
        case 'image':
            return <FileUploadInput
                onChange={onChange}
                value={value}
                title={fieldData.title}
                disabled={disabled}
                required={fieldData.required}
                requestData={requestData}
            />
        case 'multiplefile':
            return <UploadMultipleFileInput
                onChange={onChange}
                value={value}
                title={fieldData.title}
                code={fieldData.field}
                disabled={disabled}
                required={fieldData.required}
            />
        case 'search': {
            return <SearchField
                field={fieldData}
                disabled={disabled}
                inputValue={value || ''}
                onSearchValue={(value) => {
                    handleChangeRequestSaleProperties({ [fieldData.field]: value });
                }}
                onSelectOption={(option) => {
                    if (!option?.values) return;
                    const newValues = option.values.reduce((p, c) => ({ ...p, [c[0]]: c[1] }), {});
                    handleChangeRequestSaleProperties(newValues);
                }}
            />
        }
        case 'autoincrementid': {
            if (typeof value !== 'number') return null;

            return <TextField
                label={fieldData.title}
                value={`#${value}`}
                variant="outlined"
                autoComplete="off"
                disabled={true}
                fullWidth
                multiline
            />
        }
        case 'multiselectarray':
            const options = fieldData.options.filter(handleOptionSelect).map((o) => ({ id: o.key, label: o.title }));

            if (fieldData.autocompletable) {
                return (
                    <FormControl fullWidth disabled={disabled} required={fieldData?.required}>
                        <Autocomplete
                            multiple
                            id="autocomplete-array"
                            options={options}
                            getOptionLabel={(option) => option.label}
                            value={options.filter(option => (value || []).includes(option.id))}
                            onChange={(__, newValue) => {
                                if (!disabled) {
                                    handleChangeRequestSaleProperties({
                                        [fieldData.field]: (newValue || []).map(item => item?.id).filter(Boolean)
                                    });
                                }
                            }}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            disabled={disabled}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={`${fieldData.title}${fieldData?.required ? ' *' : ''}`}
                                    placeholder="Buscar..."
                                    disabled={disabled}
                                />
                            )}
                         
                        />
                    </FormControl>

                )
            }

            return (
                <FormControl fullWidth disabled={disabled} required={fieldData?.required}>
                    <InputLabel id="o-box-demo-array-label">{fieldData.title}</InputLabel>
                    <Select
                        labelId="o-box-demo-array-label"
                        id="o-box-demo-array"
                        multiple
                        value={Array.isArray(value) ? value : []}
                        onChange={onChange}
                        label={fieldData.title}
                        renderValue={(selected) => selected.map((val) => {
                            const option = options.find(opt => opt.id === val);
                            return option ? option.label : val;
                        }).join(', ')}
                    >
                        {options.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )
    }
    return <>{JSON.stringify(fieldData)}</>
}

interface CustomTextInputProps {
    value: string,
    onChange: (value: any) => void,
    error?: boolean,
    disabled?: boolean,
    helperText?: string,
    title?: string,
    required?: boolean
}

export function CustomTextInput({
    value,
    onChange,
    error,
    disabled,
    helperText,
    title,
    required = false
}: CustomTextInputProps) {
    const [expanded, setExpanded] = useState(true);

    const handleToggleExpand = () => {
        setExpanded(prev => !prev);
    };

    return (
        <div style={{ position: 'relative' }}>
            <TextField
                fullWidth
                label={title}
                autoComplete="off"
                multiline
                error={error}
                required={required}
                value={value || ''}
                onChange={onChange}
                disabled={disabled}
                variant="outlined"
                helperText={helperText}
                maxRows={expanded ? 6 : undefined}
                inputProps={{ style: { paddingRight: '48px' } }}
            />
            <IconButton
                onClick={handleToggleExpand}
                style={{ position: 'absolute', right: 8, top: 8 }}
                disabled={disabled}
            >
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
        </div>
    );
}

export default ProgramableField