import { LoadingButton } from '@/components/buttons/loading-button'
import { cn } from '@/lib/utils'
import { forwardRef } from 'react'
import { ButtonProps } from '../button'
import SimpleTooltip from '../simple-tooltip'

export interface BtnNextStepProps extends ButtonProps {
    tooltip: string,
    loading?: boolean
}

const ButtonWithTooltip = forwardRef<HTMLButtonElement, BtnNextStepProps>(({
    className,
    variant = 'default',
    children,
    tooltip,
    loading,
    ...props
}, ref) => {
    return (
        <SimpleTooltip content={tooltip}>
            <LoadingButton
                type="button"
                loading={loading}
                className={cn("group overflow-hidden relative shrink-0", className)}
                variant={variant as 'default'}
                ref={ref}
                {...props}
            >
                {children}
            </LoadingButton>
        </SimpleTooltip>
    )
})

export default ButtonWithTooltip
