import { isAuth } from "@/actions/auth"
import { Feature, isFeatureEnabled } from "@/helpers/featureFlagHelpers"
import { TCustomStyles } from "@/interfaces/Interface"
import { LoaderForInfinityScroll } from "@/pages/training/instances"
import "@/styles/scrollModify.css"
import moment, { Moment } from "moment"
import { MutableRefObject, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getInteractions } from "../../redux/slices/crm"
import useOnScreen from "../core/useOnScreen"
import InfiniteScroll from "../ui/infinite-scroll"
import ActionsToRemember from "./ActionsToRemember"
import Bubble from "./Bubble"
import EndMesageComponent from "./Bubble/EndMesageComponent"
import FloatingButtons from "./UnAssigned/FloatingButtons"

moment.locale("es")

interface ConversationProps {
  nextProspect: () => void
  refScrollConversation: any
  customStyles?: TCustomStyles | null
  enableNextProspect?: boolean,
  downChatFc: any
  scrolleableDivRef: MutableRefObject<HTMLDivElement | null>
}

const Conversation = ({
  nextProspect,
  enableNextProspect,
  refScrollConversation,
  customStyles,
  downChatFc,
  scrolleableDivRef
}: ConversationProps) => {
  const prospect = useSelector((state: any) => state.crm.contact)
  const interactionsBase = useSelector((state: any) => state.crm.contact.interactions)
  const moreChats = useSelector((state: any) => state.crm.moreChats)
  const agent = useMemo(() => isAuth(), [])

  const activeFilters = useSelector((state: any) => state.crm.activeInteractionFilters)
  const searchValue = useSelector((state: any) => state.crm.searchValue)

  const dispatch = useDispatch()

  const secondInteractionRef = useRef(null)
  const isVisibleSecondInteraction = useOnScreen(secondInteractionRef, [interactionsBase.length, secondInteractionRef])
  const isVisibleFirstInteraction = useOnScreen(refScrollConversation, [interactionsBase.length, refScrollConversation])

  const enableSnooze = isFeatureEnabled(Feature.ENABLE_SNOOZE);

  const interactions = useMemo(() => {
    if (!interactionsBase || interactionsBase.length === 0) return [];

    const reverseInteractions = [...interactionsBase].reverse();
    let interactionsList: any[] = [];
    let agroup: any[] = [];
    let agroupCount = 0;

    let lastDate: null | Moment = null;

    reverseInteractions.forEach((interaction, index) => {
      if (!interaction.createdAt) return;

      let prevInteraction = index > 0 ? reverseInteractions[index - 1] : null;
      let nextInteraction = index < reverseInteractions.length - 1 ? reverseInteractions[index + 1] : null;

      let currentDate = moment(interaction.createdAt).startOf('day');
      const addDate = lastDate === null || !lastDate.isSame(currentDate, 'day'); // Verificamos si es un nuevo día

      // Función que agrega la agrupación actual a la lista
      const addAgrup = () => {
        if (agroup.length == 0) return;

        interactionsList.unshift({
          _id: `agroup-${agroupCount}`,
          type: 'agroup',
          agroup: [...agroup]
        });

        agroup = [];
        agroupCount++;
      }

      // Validar si se agrega una fecha nueva y existe una agrupación pasada
      if (lastDate && addDate && agroup.length > 0) addAgrup();

      // Agregamos interacción de fecha
      if (addDate) {
        interactionsList.unshift({
          _id: currentDate.format("L"),
          createdAt: interaction.createdAt,
          type: "date",
        });
        lastDate = currentDate;
      }

      // Origenes de las interacciones
      const originOfCurrent = interaction?.content?.origin || 'agent';
      const originOfNext = nextInteraction?.content?.origin || 'agent';
      const originOfPrev = prevInteraction?.content?.origin || 'agent';

      const compareInteractions = (compare: any, origin: 'agent' | 'contact') => {
        if (!compare || origin !== originOfCurrent) return false;

        if (originOfCurrent === 'agent' && interaction?.agent?._id !== compare?.agent?._id) return false
        if (compare.type !== interaction.type) return false;
        if (interaction.type == 'channelMessage' && compare.content?.medium !== interaction.content?.medium) return false;
        if (interaction.type == 'reminder' && (!interaction.content.reminderDate || !compare.content.reminderDate)) return false;
        return true;
      }

      // Validar si la interacción anterior cumple con los mismos parametros que la actual
      const prevValidation = compareInteractions(prevInteraction, originOfPrev);
      // Validar si la interacción siguiente cumple con los mismos parametros que la actual
      const nextValidation = compareInteractions(nextInteraction, originOfNext);

      if (prevValidation || nextValidation) {
        // Si la interacción anterior no es igual a la actual agregamos la agrupación
        if (!prevValidation) addAgrup();
        agroup.unshift(interaction);
      } else {

        // Validamos si existe agrupación a agregar
        addAgrup();

        // Validamos si la siguiente interacción es igual a la actual y su fecha es la misma para agregar la interacción actual a la agrupación
        if (nextValidation && moment(nextInteraction.createdAt).isSame(currentDate, 'day')) {
          agroup = [interaction];
        } else {
          interactionsList.unshift(interaction);
        }
      }
    });

    // Verifica si existe agrupación pendiente.
    if (agroup.length > 0) {
      interactionsList.unshift({
        _id: `agroup-${agroupCount}`,
        agroup: [...agroup],
        type: 'agroup'
      });
    }

    // Start snooze validation
    if (enableSnooze && !prospect.reminder) {
      const lastItem = interactionsList[0];

      const snoozeInteractionContent = {
        snooze: { unit: 'hours', value: 24 },
        type: 'snooze'
      };

      if (lastItem.type == 'agroup') {
        let lastInteraction = lastItem.agroup[0];

        if (lastInteraction?.content?.origin == 'agent') {
          interactionsList[0].agroup.unshift(snoozeInteractionContent)
        };

      } else if (lastItem?.content?.origin == 'agent') {
        interactionsList.unshift(snoozeInteractionContent);
      }
    }
    // End snooze validation;

    return interactionsList;
  }, [interactionsBase, enableSnooze]);

  const handleLoadMoreInteraction = () => {
    let lastInteraction = interactions[interactions.length - 1];

    if (lastInteraction && lastInteraction.type == 'agroup') {
      lastInteraction = lastInteraction.agroup[0];
    };

    dispatch(
      getInteractions({
        date: new Date(lastInteraction.createdAt).toISOString(),
        filters: activeFilters,
        id: prospect._id,
        isLoadMore: true,
        searchValue
      })
    )
  }

  return (
    <>
      <InfiniteScroll
        className="flex flex-col-reverse px-[15px] bg-crm pt-10 absolute top-0 left-0 w-full h-full"
        endMessage={<EndMesageComponent prospect={prospect} />}
        loader={<LoaderForInfinityScroll />}
        dataLength={interactionsBase.length}
        next={handleLoadMoreInteraction}
        ref={scrolleableDivRef}
        hasMore={moreChats}
        rootMargin="500px"
        as="ul"
      >
        <div
          className="h-[2px] w-full mt-8"
          key="refScrollConversation"
          ref={refScrollConversation}
          id="scrollableChat"
        />

        <ActionsToRemember
          archivingReason={prospect.archivingReason}
          reminder={prospect.reminder}
          interactions={interactions}
          prospectID={prospect._id}
          status={prospect.status}
        />
        {
          interactions.map((interaction, index) => {
            if (interaction.type == 'agroup') {
              const origin = interaction?.agroup?.[0]?.content?.origin || 'agent';

              return interaction.agroup.map((subInteraction, subIndex) => {
                const isLastSnooze = interaction.agroup?.[subIndex - 1]?.type == 'snooze';

                return (
                  <li
                    id={subInteraction._id}
                    key={subInteraction._id}
                    className={`${isLastSnooze ? 'mb-[9px]' : subIndex == 0 ? 'mb-[26px]' : 'mb-[10px]'}`}
                  >
                    <Bubble
                      hiddenProfile={origin == 'agent' ? subIndex > 0 && !isLastSnooze : subIndex < interaction.agroup.length - 1}
                      interaction={subInteraction}
                      customStyles={customStyles}
                      agent={agent}
                    />
                  </li>
                )
              })
            }

            const isLastSnooze = interactions?.[index - 1]?.type == 'snooze';

            return (
              <li
                id={interaction._id}
                key={interaction._id}
                className={`${isLastSnooze ? 'mb-[9px]' : index == 0 ? '' : 'mb-[26px]'}`}
              >
                <Bubble
                  customStyles={customStyles}
                  interaction={interaction}
                  agent={agent}
                />
              </li>
            )
          })
        }
      </InfiniteScroll>
      {
        (!isVisibleSecondInteraction || !isVisibleFirstInteraction || !!enableNextProspect) && <div className="bottom-0 right-0 absolute z-30">
          <FloatingButtons
            isVisible={isVisibleSecondInteraction || isVisibleFirstInteraction}
            bajarChat={() => downChatFc({ behavior: "smooth" })}
            nextContactVisible={enableNextProspect as boolean}
            nextConctact={nextProspect}
          />
        </div>
      }
    </>
  )
}

export default Conversation