

import { Button } from '@/components/ui/button';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import { useToggle } from '@/hooks/useToggle';
import { setAction, setEditInteraction, setReply } from '@/redux/slices/crm';
import dayjs from 'dayjs';
import _ from 'lodash';
import { ChevronDown, NotebookPen, PencilLine, Reply, Settings, Trash2 } from 'lucide-react';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import LastPreviusMessages from './LastPreviusMessages';
import ModalDeleteWhatsAppMessage from './ModalDeleteWhatsAppMessage';

interface WhatsAppActionsProps {
    id: string,
    contactId: string,
    interaction: any,
    open: boolean,
    onChangeOpen: (value: boolean) => void,
    isFromContact?: boolean
}

export default function WhatsAppActions(props: WhatsAppActionsProps) {

    const [showPreviusMessages, setShowPreviusMessages] = useState<boolean>();
    const { contactId, id, interaction, onChangeOpen, open, isFromContact } = props;

    const [openDeleteMessage, toggleDeleteMessage] = useToggle<boolean>();

    const dispatch = useDispatch();
    const isDeletedMesage = interaction.content.deleted;

    const createdAtToDate = new Date(interaction.createdAt);
    const graceDeleteDate = createdAtToDate > dayjs().subtract(1, 'hour').toDate();
    const graceEditDate = createdAtToDate > dayjs().subtract(15, 'minute').toDate();

    const enabledForEdit = useMemo(() => {
        return !isFromContact && !!(interaction?.content?.message || '').trim() && graceEditDate
    }, [interaction, graceEditDate])

    const onEditMessage = () => {
        dispatch(setAction("update-whatsapp-message"))
        dispatch(setEditInteraction(interaction));
        onChangeOpen(false);
    };

    const previousMessages = _.get(interaction, 'content.previousMessages', []);
    const enabledPreviusMessages = previousMessages.length > 0;

    if (showPreviusMessages) {
        return <LastPreviusMessages
            onOpenChange={setShowPreviusMessages}
            lastPreviusMessages={previousMessages}
        />
    };

    const handleReplyMessage = () => {
        const { message, mediaType, media, mediaURL, fileName,
            localId, thumbnail, channel, channelName, sticker, channelColor } = interaction?.content || {};

        dispatch(setReply({
            message, mediaType, media, mediaURL,
            fileName, localId, thumbnail, channel,
            type: interaction.type,
            id: interaction._id, sticker, channelName,
            channelColor
        }));

        onChangeOpen(false);
    };

    const lineBottomResponseAction = enabledPreviusMessages || (enabledForEdit && !isDeletedMesage) || (!isFromContact && graceDeleteDate && !isDeletedMesage)

    return (
        <>
            {
                openDeleteMessage && (
                    <ModalDeleteWhatsAppMessage
                        onOpenChange={toggleDeleteMessage}
                        interaction={interaction}
                        open={openDeleteMessage}
                        contactId={contactId}
                        id={id}
                    />
                )
            }
            <DropdownMenu onOpenChange={onChangeOpen} open={open}>
                <DropdownMenuTrigger asChild>
                    <Button
                        variant="outline"
                        className="flex items-center group h-nax h-auto px-1 py-1 rounded-2xl justify-center"
                    >
                        <Settings size={14} className='shrink-0' />
                        <ChevronDown size={12} className='ml-2 group-hover:translate-y-0.5 duration-100 shrink-0' />
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-48" align="end">
                    <DropdownMenuItem className='h-[30px]' onClick={handleReplyMessage}>
                        <Reply size={17} className="mr-3" />
                        Responder
                    </DropdownMenuItem>
                    {lineBottomResponseAction && <DropdownMenuSeparator />}
                    {
                        enabledPreviusMessages && (
                            <>
                                <DropdownMenuItem className='h-[30px]' onClick={() => {
                                    setShowPreviusMessages(true);
                                }}>
                                    <NotebookPen size={17} className="mr-3" />
                                    Ver ediciones pasadas
                                </DropdownMenuItem>
                            </>
                        )
                    }
                    {
                        (enabledForEdit && !isDeletedMesage) && (
                            <>
                                {enabledPreviusMessages && <DropdownMenuSeparator />}
                                <DropdownMenuItem className='h-[30px]' onClick={onEditMessage}>
                                    <PencilLine size={17} className="mr-3" />
                                    Editar
                                </DropdownMenuItem>
                            </>
                        )
                    }
                    {
                        (!isFromContact && graceDeleteDate && !isDeletedMesage) && (
                            <>
                                {enabledForEdit && <DropdownMenuSeparator />}
                                <DropdownMenuItem className='h-[30px]' onClick={() => {
                                    onChangeOpen(false);
                                    toggleDeleteMessage();
                                }}>
                                    <Trash2 size={17} className="mr-3" />
                                    Eliminar
                                </DropdownMenuItem>
                            </>
                        )
                    }
                </DropdownMenuContent>
            </DropdownMenu>
        </>
    )
};