import { Button } from "@/components/ui/button";
import { AdditionalCompanyField, ICompanyGroup } from "@/interfaces/Interface";
import { setToggleCreateContact } from "@/redux/slices/crm";
import { Routes } from "@/routes";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import ViewOnMapButton from "../../view-on-map-button";
import AdditionalField from "./additional-field";
import CompanyDescription from "./company-description";
import { getIdentificationTermByCompany } from "@/helpers/identificationTerms";


interface CompanyInformationProps {
    companyGroup: ICompanyGroup,
    additionalCompanyFields: AdditionalCompanyField[]
}

export default function CompanyInformation({
    companyGroup,
    additionalCompanyFields = []
}: CompanyInformationProps
) {
    if (!companyGroup) return null;

    const { displayName, taxCode, direction, _id: companyGroupId, additionalData = {} } = companyGroup || {}
    const { formatted_address, place_id } = direction || {}

    const dispatch = useDispatch()
    const onCreateContactModal = (value: boolean) => dispatch(setToggleCreateContact(value))

    const router = useRouter()
    const onHandleViewCompanyGroup = () => {
        onCreateContactModal(false)
        router.push(`${Routes.BUSSINES}/${companyGroupId}`)
    }

    return (
        <div className="grid gap-3">
            <CompanyDescription
                title="Razon social:"
                description={displayName}
            />
            <CompanyDescription
                title={`${getIdentificationTermByCompany('rut')}:`}
                description={taxCode}
            />
            {
                formatted_address && <div>
                    <CompanyDescription
                        title="Dirección:"
                        description={formatted_address}
                    />
                    <ViewOnMapButton
                        className="mt-2 text-primary"
                        address={formatted_address}
                        placeId={place_id}
                        variant={'link'}
                    />
                </div>
            }
            {
                additionalCompanyFields.map(({ code, ...rest }, index) => {
                    if (!additionalData[code]) return null;
                    return (
                        <AdditionalField
                            key={`${code}${index}`}
                            field={{ ...rest, code }}
                            value={additionalData[code]}
                        />
                    )
                })
            }
            <Button
                onClick={onHandleViewCompanyGroup}
                className="w-full"
                variant="link"
                type="button"
            >
                Ver empresa
            </Button>
        </div>
    )
}