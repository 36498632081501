import { getSub } from "@/actions/group"
import { Button } from "@/components/ui/button"
import EmptyMessage from "@/components/ui/complex/empty-action"
import { InputSearch } from "@/components/ui/input-search"
import { searchRegex } from "@/helpers/regex"
import useLoginPush from "@/hooks/admin/useLoginPush"
import { useQuery } from "@tanstack/react-query"
import _ from "lodash"
import { Users } from "lucide-react"
import { useEffect, useMemo, useState } from "react"
import ChangeAccountLoaderData from "./ChangeAccountLoaderData"
import SubGroupCardItem from "./SubGroupCardItem"
import UserItemCard from "./UserItemCard"

interface SelecteableCompanyUsersProps {
    onOpenChange: (value: boolean) => void
    onHandleBack?: () => void,
    company: string,
}

export function SelecteableCompanyUsers({ company, onHandleBack, onOpenChange }: SelecteableCompanyUsersProps) {
    const [searchValue, setSearchValue] = useState<string>('');
    const [groupSelected, setGroupSelected] = useState<any>();

    const { loginByEmail } = useLoginPush()

    const { data, isLoading } = useQuery({
        queryKey: ['company-groups', company],
        enabled: !!company,
        queryFn: () => getSub(company, {
            groupFields: ["ancestors", "createdAt", "active", "_id", "name", "country", "displayName", "logo", "accountManager"],
            userFields: ["name", "lastname", "email", "_id", "photo", "roles", "userType", "active"],
            unpopulateAccountManager: true,
            unpopulateAncestors: true,
            activeUsers: true
        })
    });

    const companyThree = useMemo(() => {
        if (!data) return [];

        const groupsObject = _.keyBy(data, '_id');
        const groupMap: Map<string, Record<string, any>> = new Map();
        const sorted = data.sort((a, b) => a.ancestors.length - b.ancestors.length)

        sorted.forEach((group) => {
            if (group.ancestors.length == 0) {
                groupMap.set(group._id, { ...group, groups: [] });

            } else {
                const path = group.ancestors;

                if (!groupMap.get(path[0])) {
                    const group = groupsObject[path[0]];
                    if (!group) return;

                    groupMap.set(path[0], { ...group, groups: [] });
                }

                let mainGroup = groupMap.get(path[0]);

                const handleSetGroupFn = (parent: any, path: string[]) => {
                    if (path.length == 0) {
                        parent.groups.push({ ...group, groups: [] });

                    } else {
                        const parentId = path[0];
                        let groupIndex = parent.groups.findIndex((gp) => gp._id == parentId)

                        if (groupIndex == -1) {
                            const group = groupsObject[parentId]
                            if (!group) return;

                            parent.groups.push({ ...group, groups: [] })
                            groupIndex = parent.groups.length - 1;
                        }

                        const parentGroup = parent.groups[groupIndex];
                        handleSetGroupFn(parentGroup, path.slice(1, path.length))
                    }
                };

                handleSetGroupFn(mainGroup, path.slice(1, path.length));
            }
        });

        return [Array.from(groupMap.values())[0]]
    }, [data])

    const sortUsers = (a, b) => {
        const fullNameA = [a.name, a.lastname].filter(Boolean).join('').toLowerCase()
        const fullNameB = [b.name, b.lastname].filter(Boolean).join('').toLowerCase()
        return fullNameA.localeCompare(fullNameB);
    };

    const allUsers = useMemo(() => {
        let users: any[] = [];

        const extractUsers = (group: any) => {
            users.push(...group.users.sort(sortUsers));
            group.groups.forEach(extractUsers)
        }

        companyThree.forEach(extractUsers);
        return users
    }, [companyThree]);

    const sortedAllUsers = useMemo(() => [...allUsers].sort(sortUsers), [allUsers])

    const userWithprojectManagerRole = useMemo(() => {
        return allUsers.find((user) => {
            return user?.roles?.includes('project_manager')
        });
    }, [allUsers]);

    const mainGroup = companyThree[0];

    const groups = useMemo(() => {
        return (groupSelected?.groups ?? []).sort((a, b) => {
            const nameA = a.name ?? a.displayName ?? "";
            const nameB = b.name ?? b.displayName ?? "";
            return nameA.localeCompare(nameB);
        });
    }, [groupSelected]);

    useEffect(() => setGroupSelected(mainGroup), [mainGroup])

    const handleBackPressButton = () => {
        if (mainGroup && !(mainGroup._id == groupSelected._id)) return setGroupSelected(mainGroup);
        if (onHandleBack) onHandleBack()
    }

    const loginPush = (email: string) => loginByEmail(email, () => onOpenChange(false));

    const onHandleUseProjectManagerUser = async () => {
        if (!userWithprojectManagerRole) return;
        loginPush(userWithprojectManagerRole.email)
    };

    const usersList = useMemo(() => {
        if (!groupSelected?.users) return [];

        const users: Record<string, { label: string, users: any[] }> = {
            projectManager: {
                label: 'Gestores de proyecto',
                users: []
            },
            comercial: {
                label: 'Comerciales',
                users: []
            },
            super: {
                label: 'Supervisores',
                users: []
            },
            user: {
                label: 'Vendedores',
                users: []
            }
        };

        groupSelected.users.forEach((user) => {
            if (user.roles.includes('project_manager')) {
                users.projectManager.users.push(user);
            } else if (user.roles.includes('comercial')) {
                users.comercial.users.push(user);
            } else if (user.roles.includes('super')) {
                users.super.users.push(user);
            } else if (user.roles.includes('user')) {
                users.user.users.push(user);
            }
        });

        Object.values(users).forEach(group => {
            group.users.sort(sortUsers);
        });

        return Object.values(users).flatMap(({ users }) => users);
    }, [groupSelected]);

    const usersToUse = useMemo(() => {
        if (searchValue.trim()) {
            const regex = searchRegex(searchValue);

            return sortedAllUsers.filter((user) => {
                return regex.test(`${user.name} ${user.lastname}`) || regex.test(user.email)
            })
        };

        return usersList;
    }, [usersList, searchValue, sortedAllUsers])

    const isWithoutUsers = (usersToUse.length == 0 && groups.length == 0 && !searchValue.trim())

    return <div>
        <InputSearch
            type="text"
            autoFocus
            value={searchValue}
            onChangeValue={setSearchValue}
            placeholder={'Buscar usuario...'}
            className="py-4.5 border rounded focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0 mb-2 bg-gray-50 text-[12px]"
        />
        {
            isLoading
                ? <ChangeAccountLoaderData />
                : groupSelected && (
                    <>
                        <ul className="flex flex-col max-h-[400px] overflow-y-auto border rounded">
                            {
                                !searchValue && groups.map((group, inx) => {
                                    if (('active' in group) && !group.active) return null;
                                    
                                    return <SubGroupCardItem
                                        group={group}
                                        key={group._id}
                                        isLastItem={inx == groups.length - 1}
                                        onClick={() => setGroupSelected(group)}
                                        defaultCountry={groupSelected.country}
                                    />
                                })
                            }
                            {
                                (isWithoutUsers || (searchValue.trim() && usersToUse.length == 0))
                                    ? <EmptyMessage
                                        message={isWithoutUsers ? "Sin usuarios" : "Sin resultados"}
                                        classNameCard="border-none"
                                        icon={<Users size={26} className="text-gray-700" />}
                                        description={isWithoutUsers
                                            ? "El grupo seleccionado no tiene usuarios"
                                            : "No se encontró resultados para la busqueda"
                                        }
                                    />
                                    : usersToUse.map((user, index) => (
                                        <UserItemCard
                                            isFirstItem={index == 0 && groups.length > 0 && !searchValue.trim()}
                                            isLastItem={index == usersToUse.length - 1}
                                            onHandlePress={() => loginPush(user.email)}
                                            searchValue={searchValue}
                                            key={`user-${index}`}
                                            group={groupSelected}
                                            company={mainGroup}
                                            user={user}
                                        />
                                    ))
                            }
                        </ul>
                    </>
                )
        }
        <div className="mt-2 space-y-1.5 w-full">
            {
                userWithprojectManagerRole && (
                    <Button
                        onClick={onHandleUseProjectManagerUser}
                        className="w-full"
                        animateClick
                        type="button"
                    >
                        Usar como gestor de proyectos
                    </Button>
                )
            }
            {
                ((!onHandleBack && mainGroup?._id !== groupSelected?._id) || onHandleBack) && (
                    <Button
                        animateClick
                        type="button"
                        variant="outline"
                        className="w-full"
                        onClick={handleBackPressButton}
                    >
                        {
                            ((!mainGroup || (mainGroup?._id == groupSelected?._id)) && !!onHandleBack)
                                ? 'Volver'
                                : 'Volver al grupo principal'
                        }
                    </Button>
                )
            }
        </div>
    </div>
}

