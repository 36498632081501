import { getAllCompanies } from "@/actions/admin"
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog"
import { mapOfcountryList } from "@/helpers/countryList"
import { useQuery } from "@tanstack/react-query"
import "moment/locale/es"
import React, { useEffect, useMemo, useState } from "react"
import ChangeAccountLoaderData from "./ChangeAccountLoaderData"
import CompaniesList from "./CompaniesList"
import { SelecteableCompanyUsers } from "./SelecteableCompanyUsers"


interface CompanyModalProps {
    dialogTrigger: React.ReactElement
}

export const OtherCompanyModal = ({ dialogTrigger }: CompanyModalProps) => {
    const [companyId, setCompanyId] = useState<string>('')
    const [open, onOpenChange] = useState(false)

    const { data: allGroups, isLoading } = useQuery({
        queryFn: () => getAllCompanies(),
        queryKey: ['get-all-groups']
    });

    useEffect(() => {
        if (open) setCompanyId('')
    }, [open])

    const companies = useMemo(() => {
        if (!allGroups?.data) return []

        return allGroups?.data.map((group) => {
            group.countryName = mapOfcountryList[group.country.toLowerCase()]?.name ?? group.country
            return group
        })
    }, [allGroups])

    const onHandleDeselectCompany = () => setCompanyId('')

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogTrigger asChild>
                {dialogTrigger}
            </DialogTrigger>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader>
                    <DialogTitle>Cambiar cuenta desde otra empresa</DialogTitle>
                    <DialogDescription>
                        Selecciona el grupo que deseas ver los usuarios para cambiar de cuenta.
                    </DialogDescription>
                </DialogHeader>
                {!companyId
                    ? (
                        isLoading ? (
                            <ChangeAccountLoaderData />
                        ) : <CompaniesList
                            companies={companies}
                            onOpenChange={onOpenChange}
                            onSelectCompany={setCompanyId}
                        />
                    ) : <SelecteableCompanyUsers
                        onHandleBack={onHandleDeselectCompany}
                        onOpenChange={onOpenChange}
                        company={companyId}
                    />
                }
            </DialogContent>
        </Dialog>
    )
}