import FormatHtml from "@/components/format-html"
import {
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from "@/components/ui/card"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"
import { AgentOrNull } from "@/interfaces/Interface"
import { Expand, MailCheck, MailQuestion, MailWarning } from "lucide-react"
import moment from "moment"
import { useState } from "react"
import ModalViewEmail from "./Email/ModalViewEmail"
import { Email } from "./Icons"
import InteractiveMessageCard from "./InteractiveMessageCard"
import { IAgentData } from "./TransferBubble"

moment.locale("es")

interface IEmailBubble {
  content: any
  profile: string | null
  origin: string
  hiddenProfile?: boolean,
  agent?: AgentOrNull | IAgentData
  customStyle?: string
  status?: string,
  createdAt?: string | Date
}


const EmailBubble = ({
  content,
  origin,
  profile,
  hiddenProfile,
  agent,
  status,
  createdAt
}: IEmailBubble) => {
  const [open, setOpen] = useState<string | boolean>(false)

  const recipientsText = content.recipients
    .map((recipient) => recipient.email)
    .join(", ")

  const extractResponseMessage = (resume: string | undefined, body: string) => {
    if (resume) {
      let sanitizedResume = resume.replace(/<[^>]*>?/gm, '');
      const startIndex = sanitizedResume.indexOf("El");
      if (startIndex !== -1) {
        return sanitizedResume.substring(0, startIndex).trim();
      }
      return sanitizedResume;
    }
    return body;
  };

  const responseMessage = extractResponseMessage(content.resume, content.body)

  return (
    <>
      <ModalViewEmail open={open} close={() => setOpen(false)} />
      <InteractiveMessageCard
        agent={agent}
        origin={origin as any}
        mediumIcon={<Email />}
        profile={profile}
      >
        <div className="shadow-none min-w-[200px] md:min-w-[333px] p-4 w-full bg-transparent border-none">
          <CardHeader
            className={`p-0 flex flex-col border-b ${origin === "agent" ? "border-b-[#6B7280]" : "border-b-[#8f95a1]"
              } pb-2`}
          >
            <div className="flex justify-between items-center gap-4 leading-[1]">
              <div>
                <CardTitle className="text-[17px] m-0">
                  {content.subject}
                </CardTitle>
              </div>
              <TooltipProvider delayDuration={0.5}>
                <Tooltip>
                  <TooltipTrigger className="p-0 m-0">
                    <button>
                      <Expand
                        className="text-[#6B7280] leading-[0]"
                        size={18}
                        onClick={() => setOpen(content)}
                      />
                    </button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p className="text-xs">Ver completo</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <div className="flex items-end  gap-2">
              <CardDescription className="text-[#6B7280]">
                Para: {recipientsText}
              </CardDescription>
            </div>
          </CardHeader>
          <CardContent className="p-0 my-2">
            <p className="text-neutral-800 text-[14px] font-medium">
              <FormatHtml className="max-w-[350px]">
                {responseMessage}
              </FormatHtml>
            </p>
          </CardContent>
          <CardFooter className="flex flex-row justify-end m-0 p-0 gap-1 mr-1">
            <div className="flex items-center gap-1">
              <p className="font-normal text-gray-500 text-sm">
                {moment(createdAt ?? content.sentDate).format("HH:mm")}
              </p>
            </div>
            {status === 'error' ? (
              <MailWarning size={12} color="red" />
            ) : status === 'sent' || status === 'received' || status === 'done' ? (
              <MailCheck size={12} color="green" />
            ) : status === 'pending' ? (
              <MailQuestion size={12} color="yellow" />
            ) : null}
          </CardFooter>
        </div>
      </InteractiveMessageCard>
    </>
  )
}

export default EmailBubble
