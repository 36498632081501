import { Button } from "@/components/ui/button"
import { Card, CardContent, CardTitle } from "@/components/ui/card"
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import { getWordDefinition, GroupWordsDefinitions } from "@/helpers/featureFlagHelpers"
import { useQuery } from "@tanstack/react-query"
import { ArrowLeft, PlusCircle } from 'lucide-react'
import moment from "moment"
import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getRequestSaleList } from '../../../actions/crm/request-sale'
import { openRequestSale, setRequestSaleId, toggleOpenRequestSale } from "../../../redux/slices/crm"
import Loading from "../../core/Loading"
import { RequestCard } from "../ContactInformationMenu/request-item"
import RequestSaleAdd from "./RequestSaleAdd"

moment.locale('es');

interface SelectActionInterface {
    close: (boolean) => void,
    contactid: string,
    disableClose?: boolean,
    openRequest?: string,
    open?: boolean,
}

export type actions_crm = 'note' | 'whastapp' | 'visit' | 'more' | 'transfer' | 'form' | 'select' | 'sales'

interface SelectRequestSaleTemplateProps {
    goBack: () => void,
    onSelect?: (id: string) => void
}

interface RequestSaleModalState {
    defaultContent?: Record<string, any>,
    template?: string,
    request?: string,
    open: boolean
};

const defaultOpenRequestModalValue: RequestSaleModalState = {
    template: undefined,
    request: undefined,
    open: false
}

export default function RequestSale({ close, contactid, openRequest, open }: SelectActionInterface) {
    const [openRequestSaleModal, setOpenRequestSaleModal] = useState<RequestSaleModalState>(defaultOpenRequestModalValue);
    const [openSelectRequestSaleType, setOpenSelectRequestSaleType] = useState<boolean>(false)

    const dispatch = useDispatch()
    const { templates = [] } = useSelector((state: any) => state.crm.requestSale) || {};

    const { data, isLoading } = useQuery({
        queryKey: ['request-sale-list', contactid],
        queryFn: () => getRequestSaleList(contactid)
    })

    useEffect(() => {
        if (openRequest) dispatch(setRequestSaleId(openRequest))
    }, [openRequest])

    const requestSaleList = useMemo(() => data?.list || [], [data])

    const handleClickPreSale = (re) => () => {
        close(true)
        dispatch(openRequestSale({
            id: re._id,
            templateid: re.template._id
        }))
    }

    const onAddRequestSale = () => {
        if (templates.length == 1) {
            dispatch(toggleOpenRequestSale(templates[0]._id))
            return close(true)
        }

        setOpenSelectRequestSaleType(true)
    }

    const handleCloseRequestSaleModal = () => {
        setOpenRequestSaleModal(defaultOpenRequestModalValue);
        close(true);
    }

    const handleCloneRequestSale = (request: any) => {
        setOpenRequestSaleModal({
            defaultContent: request?.content ?? {},
            template: request?.template?._id,
            open: true
        });
    }

    if (openRequestSaleModal.open) {
        return <RequestSaleAdd
            defaultContent={openRequestSaleModal.defaultContent ?? {}}
            templateid={openRequestSaleModal.template}
            presaleid={openRequestSaleModal.request}
            setOpen={handleCloseRequestSaleModal}
            contactid={contactid}
        />
    }

    return (
        <Dialog onOpenChange={close} open={open}>
            <DialogContent className="max-w-[490px] px-3 flex flex-col">
                <DialogHeader className="px-2">
                    <DialogTitle>
                        {getWordDefinition<string>(GroupWordsDefinitions.REQUESTSALE) || 'Solicitudes'}
                    </DialogTitle>
                </DialogHeader>
                <div className="max-h-[450px] overflow-y-auto py-1 px-2">
                    {
                        openSelectRequestSaleType
                            ? <SelectRequestSaleTemplate
                                goBack={() => setOpenSelectRequestSaleType(false)}
                                onSelect={() => close(true)}
                            />
                            : <ul className="grid space-y-2">
                                <Button
                                    variant="outline"
                                    className="gap-2 text-[16px] h-11 w-full flex justify-start"
                                    onClick={onAddRequestSale}>
                                    <PlusCircle size={18} />
                                    Crear solicitud nueva
                                </Button>
                                {isLoading && <Loading />}
                                {
                                    requestSaleList.map((request) => {
                                        return (
                                            <li key={request._id}>
                                                <RequestCard
                                                    onCloneRequestSale={() => handleCloneRequestSale(request)}
                                                    handleEditRequest={handleClickPreSale(request)}
                                                    request={request}
                                                    key={request._id}
                                                />
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                    }
                </div>
            </DialogContent>
        </Dialog>
    )
}

export const SelectRequestSaleTemplate = ({ goBack, onSelect }: SelectRequestSaleTemplateProps) => {
    const { onceFetching, templates = [], active, isLoading } = useSelector((state: any) => state.crm.requestSale) || {};
    const dispatch = useDispatch()

    useEffect(() => {
        if (!onceFetching) return;
        if (templates.length == 1) {
            dispatch(toggleOpenRequestSale(templates[0]._id))
            return
        }
    }, [onceFetching])

    const onHandleSelect = (id: string) => {
        dispatch(toggleOpenRequestSale(id))
        onSelect && onSelect(id)
    }

    if (!active) return null

    return (
        <div className="flex flex-col justify-start">
            <div className="mb-1">
                <Button onClick={goBack} className="gap-2 px-0 text-[14px] flex h-8 justify-start" variant="link">
                    <ArrowLeft size={16} /> Volver
                </Button>
            </div>
            {isLoading && <Loading />}
            <ul className="flex flex-col gap-1">
                {templates && templates.map((template) => {
                    return (
                        <li key={template._id}>
                            <Card
                                className="hover:bg-gray-100 flex py-3 items-center cursor-pointer  duration-100"
                                onClick={() => onHandleSelect(template._id)}
                            >
                                <CardContent className="pb-0">
                                    <CardTitle className="text-gray-700 text-[15px]">
                                        {template.title}
                                    </CardTitle>
                                </CardContent>
                            </Card>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}