import { isAuth } from "@/actions/auth"
import { ButtonToOpenMenu } from "@/components/Header"
import CardError from "@/components/toaster/card-error"
import { Button } from "@/components/ui/button"
import { CardDescription, CardTitle } from "@/components/ui/card"
import { CommandGroup, CommandItem } from "@/components/ui/command"
import PupoverWithSelectItem from "@/components/ui/pupover-with-select-item"
import SimpleTooltip from "@/components/ui/simple-tooltip"
import { toast } from "@/components/ui/use-toast"
import { AppearanceConfig, Feature, getAppearance, isFeatureEnabled } from "@/helpers/featureFlagHelpers"
import { getItemFromLocalStorage, setItemToLocalStorate } from "@/helpers/localStorage"
import { useToggle } from "@/hooks/useToggle"
import { cn } from "@/lib/utils"
import _ from "lodash"
import { AlarmClock, ArchiveRestore, ArrowRight, ArrowRightLeft, Copy, Phone } from "lucide-react"
import { useRouter } from "next/router"
import { useMemo, useState } from "react"
import { IoMdAlert } from "react-icons/io"
import { useDispatch, useSelector } from "react-redux"
import { isPersonalFeatureEnables, PersonalFeature } from "../../../helpers/featureFlagHelpers"
import PhoneCallOnAppModal from "../Modal/PhoneCallOnAppModal"
import { ContactAvatar } from "../Prospect"
import { createInteraction } from "./libs/helpers/interaction"
import useGetIsBlockNumber from "./libs/hooks/useGetBlockNumber"

export function ProspectHeader({
  prospect,
  handleUpdateContact,
  onClose,
  forceBack,
  downChatFc,
  classFoceBack = '',
  toggleMenuInformation,
  id,
  functions
}) {
  const [isOpenPhoneCall, togglePhoneCall] = useToggle(false)
  const [isOpenRedirectToMobileAlert, toggleOpenRedirectToMobileAlert] = useToggle(false)
  const [phoneToCall, setPhoneToCall] = useState<string>('')

  const { handlePressBlockNumber, findNumberState } = useGetIsBlockNumber()
  const { firstName, lastName, statusTag, photo, phones, subConversation } = prospect || {}
  const router = useRouter()

  const firstBlockedNumber = useMemo(() => findNumberState(phones[0]), [findNumberState])
  const disableContactPhoto = useMemo(() => getAppearance<boolean>(AppearanceConfig.DISABLE_CONTACT_PHOTO, false), []);
  const uContactClickToCall = useMemo(() => isAuth()?.phoneProviders.find((provider) => provider.provider === 'ucontact')?.config?.enablePressToCall, [isAuth()?.phoneProviders])

  const lastInteraction = useSelector((state: any) => state?.crm?.contact?.interactions[0] || {})

  const isCalling = useMemo(() => {
    return lastInteraction && lastInteraction.type == 'phoneCall' && lastInteraction.content.state == 'calling'
  }, [lastInteraction])

  const contactFullName = [prospect?.firstName, prospect?.lastName].filter(Boolean).join(' ');

  const dispatch = useDispatch()

  const handlePhoneCall = (phone: string) => {
    const featuresAlert = getItemFromLocalStorage('features-alert', true, {});
    const enableCallOnPhone = _.get(isAuth(), 'personalConfig.callOnPhone', false);

    if (!enableCallOnPhone && !featuresAlert?.callOnPhone) {
      setItemToLocalStorate('features-alert', { ...featuresAlert, callOnPhone: true });
      setPhoneToCall(phone);
      return toggleOpenRedirectToMobileAlert();
    };

    createInteraction({
      dispatch,
      type: 'phonecall',
      data: {
        id,
        onSuccess: () => downChatFc(),
        onError: () => console.log('Error'),
        phone
      }
    })
  }

  const handlePressFirstNumber = () => {
    if (isCalling) {
      return toast({
        duration: 3000,
        className: "p-3 py-4",
        customElement: (
          <CardError
            title='El contacto ya está en llamada.'
            description={uContactClickToCall ? "Te recomendamos abrir tu discador de llamadas y revisar si tienes una llamada activa. Si no, vuelve a realizar la llamada." : "Por favor, finaliza la llamada con el contacto y vuelve a intentarlo."}
          />
        )
      })
    }

    if (phones.length > 1) {
      return togglePhoneCall()
    }

    if (firstBlockedNumber && firstBlockedNumber.disableCall) {
      return handlePressBlockNumber(firstBlockedNumber);
    }

    handlePhoneCall(prospect.phones[0])
  }

  const handlePressRestPhones = (isBlockNumber, phone) => {
    if (isBlockNumber && isBlockNumber.disableCall) return handlePressBlockNumber(isBlockNumber);
    togglePhoneCall(false);
    handlePhoneCall(phone)
  }

  const { handleTransfer, handleArchive, handleReminder, handleSubConversation } = functions || {}
  const interactiveFunctions = [
    {
      icon: <Copy />,
      label: "Sub Conversación",
      action: handleSubConversation,
      active: (isFeatureEnabled(Feature.ENABLE_SUB_CONVERSATIONS) && isPersonalFeatureEnables(PersonalFeature.ENABLE_SUB_CONVERSATIONS)) && !subConversation.contact
    },
    {
      icon: <ArrowRightLeft />,
      label: "Transferir",
      action: handleTransfer,
      active: !subConversation.contact
    },
    {
      icon: <ArchiveRestore />,
      label: "Archivar",
      action: handleArchive,
    },
    {
      icon: <AlarmClock />,
      label: "Recordar",
      action: handleReminder
    },
  ]

  return (
    <>
      <header
        className={`w-full h-[64px] md:max-w-[100%] overflow-hidden max-w-[100vw] shrink-0 border-b  border-slate-200 bg-white flex justify-between items-center ${forceBack ? "px-2" : "md:px-7 px-2"
          }`}
      >
        <div className="flex items-center max-w-[100%] overflow-hidden flex-grow justify-start">
          <ButtonBackChat
            isRightArrow={forceBack}
            classFoceBack={classFoceBack}
            onAction={onClose ? onClose : () => router.push("/contact")}
          />
          <div
            className="flex overflow-hidden truncate cursor-pointer max-w-[100%] flex-grow relative  items-center gap-2  text-left"
            onClick={() => handleUpdateContact(prospect)}
            title={contactFullName}
          >
            <ContactAvatar
              firstName={firstName}
              lastName={lastName}
              photo={!disableContactPhoto && photo}
              statusTag={statusTag}
            />
            <div className="overflow-hidden md:max-w-[180px] lg:max-w-[220px] xl:max-w-[250px] 2xl:max-w-[330px] name-prospect-container">
              <CardTitle className="md:text-[18px] max-w-[100%] truncate text-[15px] leading-5 font-semibold">
                {contactFullName}
              </CardTitle>
              <CardDescription className="truncate max-w-[100%]">
                {prospect?.originText}
              </CardDescription>
            </div>
          </div>
        </div>
        <div className="flex items-center ">
          <div className="flex items-center gap-2 ">
            <div className="flex items-center gap-2 mr-2">
              {
                interactiveFunctions
                  .filter((op) => !('active' in op) || op.active)
                  .map((option, inx) => (
                    <ToolTipHeaderButtons
                      icon={option.icon}
                      key={option.label}
                      className={inx === 0 ? "hidden sm:flex" : ""}
                      label={option.label}
                      onPress={(e) => option.action(e)}
                    />
                  ))
              }
              <PupoverWithSelectItem
                classNameList="p-0"
                classNameContent="max-w-[155px]"
                searchActive={false}
                props={
                  {
                    open: !!isOpenPhoneCall,
                    onOpenChange: handlePressFirstNumber
                  }
                }
                Trigger={
                  <div className="relative">
                    <ToolTipHeaderButtons
                      icon={<Phone />}
                      label={'Llamar'}
                      disabled={phones.length === 0 || phones[0] === "" || isCalling}
                    />
                    {
                      (phones.length === 1 && firstBlockedNumber?.disableCall) && <div className=" absolute -top-0.5 -right-0.5">
                        <IoMdAlert size={14} className="text-[#F54457]" />
                      </div>
                    }
                  </div>
                }
                CommandListContent={
                  <CommandGroup className="p-1">
                    {
                      prospect.phones.filter(Boolean).map((phone) => {
                        const isBlockNumber = findNumberState(phone)
                        return <CommandItem
                          key={phone}
                          onSelect={() => handlePressRestPhones(isBlockNumber, phone)}
                        >
                          {
                            isBlockNumber && <IoMdAlert size={14} className="mr-1 text-[#F54457]" />
                          }
                          {phone}
                        </CommandItem>
                      })
                    }
                  </CommandGroup>
                }
              />
            </div>
            <ButtonToOpenMenu
              onClick={() => toggleMenuInformation()}
              right
              className="text-neutral-900 hover:text-neutral-700"
            />
          </div>
        </div>
      </header>
      <PhoneCallOnAppModal
        open={isOpenRedirectToMobileAlert}
        onOpenChange={toggleOpenRedirectToMobileAlert}
        startCall={() => handlePhoneCall(phoneToCall)}
      />
    </>
  )
}

export function ToolTipHeaderButtons({
  icon,
  label,
  onPress,
  disabled = false,
  className = "",
}: any) {
  return (
    <SimpleTooltip content={label}>
      <Button
        size={"icon"}
        className={cn(
          "shrink-0 sm:w-[32px] sm:h-[32px] w-[30px] h-[30px] flex items-center [&>svg]:w-[18px] [&>svg]:h-[18px] relative  text-slate-500",
          className
        )}
        variant={"ghost"}
        onClick={onPress}
        disabled={disabled}
      >
        {icon}
      </Button>
    </SimpleTooltip>
  )
}

export function ButtonBackChat({ isRightArrow, onAction, classFoceBack = '' }) {
  return (
    <Button
      variant={"ghost"}
      className={cn(`px-2 h-[25px]w-[25px] hover:bg-neutral-100 text-gray-800 flex items-center justify-center shrink-0 mr-1 ${isRightArrow ? "text-gray-500 md:flex hover:text-gray-500/70 hover:bg-transparent" : "rotate-180  md:hidden"
        }`, classFoceBack)}
      onClick={onAction}
    >
      <ArrowRight className="w-[21px] h-[21px] shrink-0" />
    </Button>
  )
}
