import { fetcher } from "@/helpers/fetcher";
import { GooglePlaceResult } from "@/interfaces/Interface";

class PlacesService {

    getPlacePredictions(search: string) {
        return fetcher<GooglePlaceResult>({
            url: `/crm/googlemaps/places/autocomplete?searchInput=${search}&useNewApi=1`
        })
    }

};

const placesService = new PlacesService();
export default placesService;