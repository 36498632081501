

export default function InformationValue({ value }: { value: string }) {
    return <p
        className={`max-w-full inline-block text-end relative text-[13.5px] group text-gray-700`}
        style={{ whiteSpace: 'normal', wordBreak: 'break-word', overflowWrap: 'break-word' }}
    >
        <span className="inline">
            {value}
        </span>
    </p>
}