import { CustomCheckbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import { cn } from "@/lib/utils";
import { Switch } from "@/components/ui/switch";
import { Button } from "@/components/ui/button";
import { Settings } from "lucide-react";
import { useState } from "react";

interface FeatureCheckboxProps {
    label?: string,
    code: string,
    checked: boolean,
    isSwitch?: boolean
    onCheckedChange: (value: boolean) => void
    classNameLabel?: string
    classNameCheckbox?: string,
    withConfigButton?: boolean,
    classNameContent?: string
    onClickConfigButton?: () => void
}


export default function FeatureCheckbox({
    label,
    code,
    checked,
    onCheckedChange,
    isSwitch,
    classNameLabel,
    classNameCheckbox,
    withConfigButton,
    onClickConfigButton,
    classNameContent
}: FeatureCheckboxProps) {
    return (
        <div className={cn('flex items-center gap-2', classNameContent)}>
            {
                (isSwitch)
                    ? <Switch
                        checked={checked}
                        name={code}
                        onCheckedChange={onCheckedChange}
                    />
                    : <CustomCheckbox
                        className={cn('rounded-sm h-[22px] w-[22px]', classNameCheckbox)}
                        checked={checked}
                        id={code}
                        onCheckedChange={onCheckedChange}
                    />

            }
            {withConfigButton &&
                <Button type='button' variant={'outline'} size={'icon'} className='text-[12px]' onClick={onClickConfigButton || (() => { })}>
                    <Settings size={16} />
                </Button>
            }
            {label &&
                <Label className={cn('text-[15px] text-gray-700', classNameLabel)} htmlFor={code}>
                    {label}
                </Label>
            }
        </div>
    )
}