import { Badge } from "../ui/badge"

const rolesBgColor = {
    'admin': '#b2dfdb',
    'comercial': '#c9e4fe',
    'users_manager': '#e1bee7',
    'super': '#ffe0b2',
    'user': 'default',
    'project_manager': '#EB8B5D'
}

const rolesColor = {
    'admin': '#00695c',
    'comercial': '#0080ff',
    'users_manager': '#6a1b9a',
    'super': '#ef6c00',
    'user': 'default',
    'project_manager': '#763E24'
}

const rolesText = {
    'admin': 'Administrador VENTIA',
    'comercial': 'Comercial/Backoffice',
    'users_manager': 'Gestor de usuarios',
    'super': 'Supervisor/a',
    'user': 'Vendedor',
    'a_prueba': 'Vendedor a prueba',
    'independiente': 'Subagente',
    'project_manager': 'Gestor de proyecto'
}

interface RolChipInterface {
    rol: string,
    userType: string
}

const RolChip = ({ rol, userType }: RolChipInterface) => {
    return <Badge
        className="text-[10px] rounded-[5px] select-none px-[5px] py-[2px] h-[20px] mr-2"
        style={{
            backgroundColor: rolesBgColor[rol],
            color: rolesColor[rol],
        }}
    >
        {rol == 'user' && userType && userType !== 'normal'
            ? rolesText[userType]
            : rolesText[rol]}
    </Badge>
}

export default RolChip