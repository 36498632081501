import { fetcher } from "@/helpers/fetcher";
import axios from "axios";
import { getCookie } from "../auth";
import { ImapConfig } from "@/components/settings/email/ImapConfigDialog";

const API_NEXT = process.env.API_NEXT

function generateHeader() {
    return {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'JWT ' + getCookie('token')
    }
}

export const getEmails = () => {
    return fetch(`${API_NEXT}/nylas`, {
        method: 'GET',
        headers: generateHeader()
    })
        .then(response => {
            if (!response.ok) throw new Error('Error al cargar email personal');
            return response.json();
        })
        .catch(err => {
            throw new Error('Error al cargar email personal');
        });
};

export const postEmail = (code: string, userId?: string) => {
    return fetch(`${API_NEXT}/nylas/authV3`, {
        method: 'POST',
        headers: generateHeader(),
        body: JSON.stringify({
            code: code,
            id: userId
        })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            throw new Error('Error al cargar email personal');
        });
};

export const getEmailContent = (data) => {
    return fetch(`${API_NEXT}/nylas/emailData`, {
        method: 'GET',
        headers: generateHeader(),
    }).then(response => {
        return response.json()
    }).catch(err => {
        throw new Error('Error al obtener contenido del email')
    })
}

export const logoutNylas = (id) => {
    return fetch(`${API_NEXT}/nylas/logoutV3?providerId=${id}`, {
        method: 'POST',
        headers: generateHeader(),
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            throw new Error('Error al desvincular email personal');
        });
};

export const addEmailSignature = (signature, emailProviderId) => {
    return fetch(`${API_NEXT}/nylas?providerId=${emailProviderId}`, {
        method: 'PATCH',
        headers: generateHeader(),
        body: JSON.stringify({
            signature: signature
        })
    })
        .then(response => {
            if (!response.ok) throw new Error('Error al cargar email personal');
            return response.json();
        })
        .catch(err => {
            throw new Error('Error al cargar email personal');
        });
};

export const getPresignedUrl = (body) => {
    return fetch(`${API_NEXT}/nylas/preSignedUrl`, {
        method: 'POST',
        headers: {
            "Authorization": "JWT " + getCookie("token"),
        },
        body: JSON.stringify(body)
    })
        .then(response => {
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                return response.json();
            } else {
                return response.text();
            }
        })
        .catch(err => {
            console.log(err)
            throw new Error('Error al subir firma al servidor');
        });
};

export const postImageToServer = ({ url, body }) => {
    return fetch(`${API_NEXT}/nylas/postImage?presignedUrl=${url}`, {
        method: 'POST',
        headers: {
            "Authorization": "JWT " + getCookie("token"),
        },
        body: body
    })
        .then(response => {
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                return response.json();
            } else {
                return response.text();
            }
        })
        .catch(err => {
            console.log(err)
            throw new Error('Error al subir firma al servidor');
        });
};

export const connectImapEmail = (config: ImapConfig) => {
    return fetcher({
        url: `/nylas/connectImap`,
        defaultContentType: true,
        method: 'POST',
        body: config
    })
}