import {
    AlertDialog,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle
} from "@/components/ui/alert-dialog";
import { cn } from "@/lib/utils";
import { AlertTriangle, XIcon } from "lucide-react";
import { LoadingButton } from "../buttons/loading-button";
import { Button } from "../ui/button";

export interface CustomAlertDeleteProps {
    open?: boolean | Record<string, any> | string | undefined | null,
    onOpenChange?: (value: boolean) => void
    title: string
    description?: string
    labelBtnDelete?: string
    labelBtnCancel?: string
    loading?: boolean
    onClose?: (value?: boolean) => void
    handleConfirm: () => void
    handleCancel: () => void
    alertCircle?: boolean
    buttonClose?: boolean
    classNameButtonRight?: string,
    error?: string | null
}

export default function CustomAlertDelete({
    open,
    onOpenChange,
    title,
    description,
    labelBtnCancel = 'Cancelar',
    labelBtnDelete = 'Eliminar',
    onClose,
    buttonClose = false,
    handleConfirm,
    handleCancel,
    alertCircle = true,
    loading,
    classNameButtonRight = '',
    error = null
}: CustomAlertDeleteProps) {
    if (!open) return null;

    return (
        <AlertDialog open={!!open} onOpenChange={onOpenChange}>
            <AlertDialogContent>

                {
                    buttonClose && (
                        <button
                            onClick={() => onClose && onClose(false)}
                            className={cn('absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 flex items-center justify-center focus:outline-none focus:ring-2 h-5 w-5 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground')}>
                            <XIcon className="h-4 w-4" />
                            <span className="sr-only">Close</span>
                        </button>
                    )
                }
                <AlertDialogHeader>
                    <>
                        {error &&
                            <div>
                                <p className="text-red-600 text-[14px]">{error}</p>
                            </div>
                        }
                        <div className="flex items-start gap-4">
                            {
                                alertCircle && <div className="w-[42px] hidden md:flex shrink-0 h-[42px] items-center justify-center bg-red-100 rounded-full">
                                    <AlertTriangle className="text-red-600" size={25} />
                                </div>
                            }
                            <div className="flex flex-col gap-1">
                                <AlertDialogTitle className="text-gray-900 text-[19px]">
                                    {title}
                                </AlertDialogTitle>
                                {
                                    description && <AlertDialogDescription className="text-gray-500 text-[14px]">
                                        {description}
                                    </AlertDialogDescription>
                                }
                            </div>
                        </div>
                    </>
                </AlertDialogHeader>
                <AlertDialogFooter className="gap-y-2">
                    <LoadingButton
                        variant={'default'}
                        className="bg-red-600 hover:bg-red-700 duration-75"
                        onClick={handleConfirm}
                        loading={loading}
                    >
                        {labelBtnDelete}
                    </LoadingButton>
                    <Button variant={'outline'} onClick={handleCancel} className={cn(classNameButtonRight)}>
                        {labelBtnCancel}
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}
