import { createSharedOption, updateSharedOption } from "@/actions/crm/contact";
import { LoadingButton } from "@/components/buttons/loading-button";
import { toastActionError } from "@/components/comercial/comercialManagment/comissions/CommisionModal";
import { Button } from "@/components/ui/button";
import { CustomFormField } from "@/components/ui/custom-form-field";
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle
} from "@/components/ui/dialog";
import { Form } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { ModalPropsBase, SharedOption } from '@/interfaces/Interface';
import { sharedOptionSchema } from "@/lib/validators/additionalField";
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from "@tanstack/react-query";
import { Palette } from "lucide-react";
import { useEffect } from "react";
import { useForm } from 'react-hook-form';
import { z } from "zod";

interface SharedOptionModalProps extends ModalPropsBase {
    onSuccessResult?: (value: SharedOption, update: boolean) => void
    option?: SharedOption,
    initialValues?: Partial<SharedOptionType>
    fieldCode: string
};

export type SharedOptionType = z.infer<typeof sharedOptionSchema>;

const defaultOptionSharedValue: SharedOptionType = {
    backgroundColor: '#0080FF',
    color: '',
    name: ''
}

const optionColors = [
    '#ffffff', '#f1f5f9', '#64748b', '#6b7280', '#78716c',
    '#ef4444', '#f97316', '#f59e0b',
    '#eab308', '#84cc16', '#06b6d4', '#0080FF',
    '#a855f7', '#ec4899', '#f43f5e',
    '#dc2626', '#ea580c', '#ca8a04'
];

export default function SharedOptionModal({
    onOpenChange,
    open,
    option,
    fieldCode,
    onSuccessResult,
    initialValues = {}
}: SharedOptionModalProps) {
    const isUpdateMode = !!option;

    const form = useForm<SharedOptionType>({
        resolver: zodResolver(sharedOptionSchema),
        defaultValues: defaultOptionSharedValue
    });

    const isValidForm = form.formState.isValid

    useEffect(() => {
        if (!open) return;

        form.reset({
            ...defaultOptionSharedValue,
            ...initialValues,
            ...(option ?? {})
        });
    }, [open])

    const { isPending, mutate } = useMutation({
        mutationFn: ({ body, type, key }: { type: 'update' | 'create', body: SharedOptionType, key?: string }) => {
            if (type == 'create') return createSharedOption(fieldCode, body)
            return updateSharedOption(fieldCode, key as string, body)
        },
        onSuccess: (result, { body }) => {
            const data = (!('option' in result)
                ? { ...(option || {}), ...body }
                : result.option) as SharedOption;

            if (onSuccessResult) onSuccessResult(data, isUpdateMode);
            onOpenChange(false);
        },
        onError: toastActionError
    });

    const onSubmit = (values: SharedOptionType) => {
        mutate({
            type: isUpdateMode ? 'update' : 'create',
            key: option?.code,
            body: values
        })
    }

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className="sm:max-w-[550px]">
                <DialogHeader>
                    <DialogTitle>
                        {isUpdateMode ? 'Editar opción' : 'Crear opción'}
                    </DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form
                        id="shared-option-form"
                        className="grid space-y-4 py-1"
                        onSubmit={(ev) => {
                            ev.stopPropagation();
                            form.handleSubmit(onSubmit)(ev)
                        }}>
                        <CustomFormField
                            name="name"
                            isRequired
                            label="Nombre"
                            control={form.control}
                            fnElement={({ field }) => {
                                return <Input
                                    placeholder="Escribe el nombre aquí..."
                                    onChange={field.onChange}
                                    value={field.value}
                                />
                            }}
                        />

                        <CustomFormField
                            name="backgroundColor"
                            label="Color"
                            control={form.control}
                            fnElement={({ field }) => {
                                return (
                                    <OptionsColors
                                        onSelect={field.onChange}
                                        value={field.value}
                                    />
                                )
                            }}
                        />
                    </form>
                </Form>
                <DialogFooter>
                    <Button
                        type="button"
                        variant="outline-primary"
                        onClick={() => onOpenChange(false)}
                    >
                        Cancelar
                    </Button>
                    <LoadingButton
                        type="submit"
                        loading={isPending}
                        disabled={!isValidForm}
                        form="shared-option-form"
                    >
                        {isUpdateMode ? 'Actualizar' : 'Crear'}
                    </LoadingButton>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

interface OptionsColorsProps {
    onSelect: (value: string) => void,
    value: string
}

export function OptionsColors({ onSelect, value }: OptionsColorsProps) {
    const isCustom = !optionColors.includes(value);

    return (
        <ul className="flex flex-wrap gap-2 items-center">
            {optionColors.map((color) => {
                const isSelected = color === value;
                return (
                    <li key={color}>
                        <Button
                            className={`w-9 h-9 rounded-md border ${isSelected ? 'border-primary' : ''}`}
                            style={{ backgroundColor: color }}
                            onClick={() => onSelect(color)}
                            variant="outline"
                            type="button"
                            animateClick
                        />
                    </li>
                );
            })}
            <li>
                <Button
                    className={`w-9 h-9 rounded-md border flex overflow-hidden items-center justify-center ${isCustom ? 'border-primary' : ''}`}
                    style={{ backgroundColor: isCustom ? value : 'transparent' }}
                    onClick={() => document.getElementById('customColorInput')?.click()}
                    type="button"
                    variant="outline"
                    animateClick
                >
                    <input
                        type="color"
                        id="customColorInput"
                        className="absolute top-0 bottom-0 m-auto opacity-0 right-0 w-8 left-0 cursor-pointer"
                        value={isCustom ? value : '#000000'}
                        onChange={(e) => onSelect(e.target.value)}
                    />
                    {!isCustom && <Palette size={18} className="shrink-0" />}
                </Button>
            </li >
        </ul >
    );
}