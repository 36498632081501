import { authenticate, getCookie, isAuth, setCookie, signin_push } from "@/actions/auth";
import { useRouter } from "next/router";

export default function useLoginPush() {

    const router = useRouter()
    const currentUser = isAuth();

    const loginByEmail = async (email: string, onSuccess?: () => void) => {
        try {
            const result = await signin_push({ email });
            if (result.error) return console.log("error haciendo login");

            const currentUserRoles = currentUser?.roles ?? [];

            if (currentUserRoles.includes('admin')) {
                setCookie("admin_token", getCookie("token"));
            }

            authenticate(result, () => {
                if (onSuccess) onSuccess();
                router.push(router.pathname);

                setTimeout(() => {
                    window.location.reload();
                }, 100);
            });
        } catch (error) {
            console.error(error);
        }
    };

    return {
        loginByEmail
    }
}
