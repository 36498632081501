
import { getCompany } from "@/actions/auth";
import { BtnNextStep } from "@/components/buttons/BtnNextStep";
import AddressInput from '@/components/crm/Inputs/AddressInput';
import { Button } from "@/components/ui/button";
import { CustomFormField } from "@/components/ui/custom-form-field";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Form } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { Textarea } from "@/components/ui/textarea";
import { getCompanyGroupConfig } from "@/helpers/companyGroups";
import { Feature, isFeatureEnabled } from "@/helpers/featureFlagHelpers";
import { replaceObjectValues } from "@/helpers/replaceObjectValues";
import { AdditionalCompanyField, DefaultFieldCompanyGroup, ICompanyGroup } from "@/interfaces/Interface";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "../../ui/select";
import AdditionalCompanyFieldItem from "./AdditionalCompanyField";
import PhoneNumberField from "./AdditionalCompanyField/PhoneNumberField";
import useCreateCompanyGroup from "./utils/hooks/useCreateCompanyGroup";
import { getIdentificationTermByCompany } from "@/helpers/identificationTerms";

interface ModalDeleteSellerProps {
    open: boolean | Record<string, any>,
    onClose: () => void,
    defaultValues?: Partial<CompanySchemaType>
    onSuccess?: (value: ICompanyGroup) => void
    templateField?: {
        contact: any,
    }
}

const transformString = value => typeof value === 'string' ? value.trim() : value

export const companySchema = z.object({
    businessName: z.string({
        required_error: "Nombre requerido"
    }).min(1, 'Requerido').transform(transformString),
    contacts: z.any().optional(),
    displayName: z.string().optional(),
    description: z.string().max(300, { message: 'La descripción no debe de superar los 300 caracteres' }).optional().transform(transformString),
    origin: z.string({
        required_error: "Origen requerido"
    }).min(1, 'Requerido').transform(transformString),
    taxCode: z.string().optional(),
    sectors: z.boolean().default(false),
    direction: z.any().optional(),
    additionalData: z.any().optional(),
});

export type CompanySchemaType = z.infer<typeof companySchema>

export const companyGroupDefaultValues = {
    businessName: '',
    displayName: '',
    sectors: false,
    description: '',
    origin: 'Carga manual',
    taxCode: '',
    additionalData: {},
    direction: '',
}

const nonReplaceableFields = new Set(['multiplecompany', 'selectcompany', 'multiplecontact', 'selectcontact', 'direction'])

export const AddCompanyModal = ({
    open,
    onClose,
    defaultValues = {},
    onSuccess,
    templateField
}: ModalDeleteSellerProps) => {
    const contextForm = useForm<CompanySchemaType>({
        resolver: zodResolver(companySchema),
        defaultValues: companyGroupDefaultValues
    });

    const isModifyBussiness = typeof open !== 'boolean'
    const values = contextForm.watch()

    const queryClient = useQueryClient()

    const handleClose = () => {
        onClose()
        contextForm.reset()
    }

    const onHandleSuccess = useCallback((data) => {
        onSuccess && onSuccess(Array.isArray(data) ? data[0] : data as ICompanyGroup)
        queryClient.invalidateQueries({ queryKey: ['table-companygroup'] })
        handleClose()
    }, [onSuccess, queryClient, handleClose])

    const { handleSubmit, isPending: isLoading, validateIfValidForm } = useCreateCompanyGroup({
        contextForm,
        isModifyBussiness,
        onSuccess: onHandleSuccess
    })

    const { title } = getCompanyGroupConfig();
    const isValidForm = contextForm.formState.isValid && validateIfValidForm(values)

    const additionalCompanyFields: AdditionalCompanyField[] = useMemo(() => {
        return _.get(getCompany(), "additionalCompanyFields", [])
            .filter(field => field.active) as AdditionalCompanyField[]
    }, [])

    const defaultFieldsSettings: DefaultFieldCompanyGroup[] = useMemo(() => {
        return _.get(getCompany(), "companyGroupSettings.defaultFields", [])
    }, [])

    const replaceTemplateVariables = useCallback((str: string) => {
        const { contact = {} } = templateField || {};

        const affiliatedCompanyGroups = (contact?.affiliatedCompanyGroup || []).map(({ companyGroup = {} }: any) => ({
            _id: companyGroup._id,
            id: companyGroup._id,
            displayName: companyGroup.displayName,
            businessName: companyGroup.businessName,
            description: companyGroup.description,
            origin: companyGroup.origin,
            taxCode: companyGroup.taxCode,
            status: companyGroup.status,
            additionalData: Object.fromEntries(Object.entries(companyGroup.additionalData || {}).map(([key, value]) => [key, [value].flat().join(', ')]))
        }));

        const contactData = contact || {};
        const contactFields = {
            phones: contactData.phones || [],
            emails: contactData.emails || [],
            firstName: contactData.firstName,
            lastName: contactData.lastName,
            _id: contactData._id,
            detail: contactData.detail,
            origin: contactData.origin,
            originText: contactData.originText,
            additionalData: contactData.additionalData
        }

        return replaceObjectValues(str, {
            affiliatedCompanyGroups,
            contact: contactFields,
            contacto: contactFields
        });

    }, [templateField])

    useEffect(() => {
        if (!isModifyBussiness) {
            const formValues = { ...companyGroupDefaultValues, ...defaultValues };

            if (templateField) {
                const getSerializeValue = (defaultValue: any, type: string) => {
                    let value: any = defaultValue;

                    if (type == 'date') {
                        const dateValue = new Date(replaceTemplateVariables(defaultValue));
                        if (!isNaN(dateValue.getTime())) value = dateValue.toISOString()

                    } else if (['multiselect', 'phonenumbers'].includes(type)) {
                        value = (Array.isArray(defaultValue) ? defaultValue : defaultValue.split(',')).map(replaceTemplateVariables)
                    } else if (type == 'number') {

                        const defaultValueUse = Number(replaceTemplateVariables(String(defaultValue)))
                        value = isNaN(defaultValueUse) ? 0 : defaultValueUse
                    } else {
                        value = replaceTemplateVariables(value)
                    }

                    return value;
                }

                additionalCompanyFields.forEach(({ defaultValue, type, code }) => {
                    if (!defaultValue || nonReplaceableFields.has(type)) return;
                    formValues['additionalData'][code] = getSerializeValue(defaultValue, type)
                })

                defaultFieldsSettings.forEach(({ defaultValue, key }) => {
                    if (!defaultValue || nonReplaceableFields.has(key)) return;
                    formValues[key] = getSerializeValue(defaultValue, 'string')
                })
            }

            return contextForm.reset(formValues)
        }

        contextForm.reset({ ...open, origin: open.origin });
    }, [open, isModifyBussiness])


    return (
        <Dialog open={!!open} onOpenChange={handleClose}>
            <DialogContent className="sm:max-w-[600px] flex flex-col h-[95vh] p-0">
                <DialogHeader className="p-5 pb-0">
                    <DialogTitle>{isModifyBussiness ? 'Editar' : 'Agregar'} {title.singular}</DialogTitle>
                    <DialogDescription>
                        Al crear {title.plural.toLowerCase()}, tendrás la capacidad de vincular contactos a un/a {title.singular.toLowerCase()} en específico.
                    </DialogDescription>
                </DialogHeader>
                <div className="flex-grow w-full relative">
                    <div className="absolute top-0 left-0 w-full h-full overflow-y-auto">
                        <Form {...contextForm}>
                            <form
                                id="formAddNewCompanyGroup"
                                className={'px-5 py-2'}
                                onSubmit={(e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    contextForm.handleSubmit(handleSubmit)(e)
                                }}
                            >
                                <FormFieldAddCompany contextForm={contextForm} />
                            </form>
                        </Form>
                    </div>
                </div>
                <DialogFooter className="bg-gray-100 border-t border-t-gray-300 py-3 px-5">
                    <Button variant={'outline-primary'} type={'button'} size="xs" onClick={onClose}>
                        Cancelar
                    </Button>
                    <BtnNextStep
                        form="formAddNewCompanyGroup"
                        type="submit"
                        variant={'california'}
                        size={'xs'}
                        disabled={!isValidForm && !isFeatureEnabled(Feature.DISABLE_MANDATORY_FIELDS_IN_COMPANIES)}
                        hiddenArrow
                        loading={isLoading}
                    >
                        Guardar
                    </BtnNextStep>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export function FormFieldAddCompany({
    contextForm,
    disabledEdit = false
}) {
    const { defaultFields, title } = getCompanyGroupConfig();
    const defaultFieldsToMap = _.keyBy(defaultFields, 'key')

    const disableMandatoryFieldsInCompanies = isFeatureEnabled(Feature.DISABLE_MANDATORY_FIELDS_IN_COMPANIES);
    const currentOrigin = contextForm.watch('origin');

    const additionalCompanyFields: AdditionalCompanyField[] = useMemo(() => {
        return _.get(getCompany(), "additionalCompanyFields", [])
            .filter(field => field.active) as AdditionalCompanyField[]
    }, [])

    const [displayName, taxCode, origin, direction, sectors] = [
        defaultFieldsToMap['displayName'],
        defaultFieldsToMap['taxCode'],
        defaultFieldsToMap['origin'],
        defaultFieldsToMap['direction'],
        defaultFieldsToMap['sectors']
    ]

    return (
        <div className="grid gap-4 ">
            <div className="grid gap-2">
                <CustomFormField
                    name="businessName"
                    control={contextForm.control}
                    label={`Nombre`}
                    isRequired
                    fnElement={({ field }) => <Input {...field} placeholder="ACME" disabled={disabledEdit} />}
                />
            </div>
            {
                !(displayName?.hidden && taxCode?.hidden) && (
                    <div
                        className={`grid grid-cols-1 gap-4 ${!(displayName?.hidden || taxCode?.hidden) ? 'md:grid-cols-2' : 'md:grid-cols-1'}`}
                    >
                        {
                            (!(displayName?.hidden)) && (
                                <CustomFormField
                                    name="displayName"
                                    control={contextForm.control}
                                    label="Razón Social"
                                    isRequired={!disableMandatoryFieldsInCompanies}
                                    fnElement={({ field }) => <Input {...field} placeholder="Acme SAAS" disabled={disabledEdit} />}
                                />
                            )
                        }
                        {
                            (!(taxCode?.hidden)) && (
                                <CustomFormField
                                    name="taxCode"
                                    control={contextForm.control}
                                    label={getIdentificationTermByCompany('rut')}
                                    isRequired={!disableMandatoryFieldsInCompanies}
                                    fnElement={({ field }) => <Input {...field} placeholder="00000000000" disabled={disabledEdit} />}
                                />
                            )
                        }
                    </div>
                )
            }
            {
                !(origin?.hidden && direction?.hidden) && (
                    <div className={`grid grid-cols-1 gap-4 ${!(origin?.hidden || direction?.hidden) ? 'md:grid-cols-2' : 'md:grid-cols-1'}`}>
                        {
                            (!(origin?.hidden)) && (
                                <CustomFormField
                                    name="origin"
                                    control={contextForm.control}
                                    isRequired
                                    label="Origen"
                                    fnElement={({ field }) => (
                                        <Select value={field.value} disabled={true}>
                                            <SelectTrigger>
                                                <SelectValue defaultValue={field.value} />
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {(field.value !== 'Carga manual') && (
                                                        <SelectItem value={field.value}>
                                                            {field.value}
                                                        </SelectItem>
                                                    )}
                                                    <SelectItem value="Carga manual">Carga Manual</SelectItem>
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                    )}
                                />
                            )
                        }
                        {
                            (!(direction?.hidden)) && (
                                <CustomFormField
                                    name="direction"
                                    control={contextForm.control}
                                    label="Dirección"
                                    fnElement={({ field }) => (
                                        <AddressInput
                                            address={field.value}
                                            askExtraInfo={false}
                                            disabled={disabledEdit}
                                            placeholder={'Selecciona una dirección...'}
                                            onAddressChange={(value) => field.onChange(value)}
                                            required={true}
                                        />
                                    )}
                                />
                            )
                        }
                    </div>
                )
            }
            <CustomFormField
                name="description"
                control={contextForm.control}
                label="Descripción"
                fnElement={({ field }) => (
                    <Textarea
                        {...field}
                        disabled={disabledEdit}
                        placeholder="Ingresa un comentario o información relevante" />
                )}
            />
            {
                additionalCompanyFields.map((additionalField, index) => {
                    if (additionalField.type == 'phonenumbers') return <PhoneNumberField
                        contextForm={contextForm}
                        field={additionalField}
                        key={additionalField.code}
                    />;

                    return (
                        <CustomFormField
                            name={`additionalData.${additionalField.code}`}
                            isRequired={additionalField.required}
                            key={`${additionalField.code}${index}`}
                            control={contextForm.control}
                            label={additionalField.name}
                            fnElement={({ field }) => (
                                <AdditionalCompanyFieldItem
                                    onChangeValue={field.onChange}
                                    field={additionalField}
                                    disabledEdit={disabledEdit}
                                    value={field.value}
                                />
                            )}
                        />
                    )
                })
            }
            {
                (isFeatureEnabled(Feature.ENABLE_COMPANY_GROUP_SECTORS) && !(sectors?.hidden)) && (
                    <div className="grid gap-1 w-full">
                        <CustomFormField
                            name="sectors"
                            control={contextForm.control}
                            fnElement={({ field }) => (
                                <div className="flex items-center w-full justify-between">
                                    <Label>
                                        Sectores
                                    </Label>
                                    <Switch onCheckedChange={field.onChange} disabled={disabledEdit} checked={field.value} />
                                </div>
                            )}
                        />
                        <p className="text-[14px] text-gray-600">
                            Esta opción te permitirá agrupar a los contactos en distintos sectores dentro de la/el {title.singular.toLowerCase()}.
                        </p>
                    </div>
                )
            }
        </div>
    )
}
