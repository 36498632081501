import { fetcher } from "@/helpers/fetcher"
import axios from "axios"
import { getCookie } from "../auth"
import { PersonalConfigSchemaType } from "@/components/settings/whatsapp/LoadContactsAlert"
const API = process.env.API
const API_NEXT = process.env.API_NEXT


export const savePersonalConfig = async (config: Partial<PersonalConfigSchemaType>) => {
    return fetcher({
        url: '/user/config/personalConfig',
        body: { config },
        method: 'PATCH',
        defaultContentType: true
    })
}

export const getPressignedUrl = async ({ imageName, imageType }) => {
    const data = {
        imageName,
        imageType
    }

    return axios.post(`${API_NEXT}/settings/getPressignedUrl`, data, {
        headers: {
            'Authorization': `JWT ${getCookie('token')}`
        }
    })
        .then(res => res.data)
        .catch(err => console.log(err))
}

export const saveAppearanceConfig = async (config) => {
    return axios.patch(`${API_NEXT}/settings/appearance`, config, {
        headers: {
            'Authorization': `JWT ${getCookie('token')}`
        }
    })
}

export const getAppearanceConfig = async () => {
    return axios.get(`${API_NEXT}/settings/appearance`, {
        headers: {
            'Authorization': `JWT ${getCookie('token')}`
        }
    }).then(res => res.data)
        .catch(err => console.log(err))
}

class UserController {
    url: string

    constructor() {
        this.url = '/settings/profile'
    }

    editProfile(data) {
        return fetcher({
            url: `${this.url}`,
            options: {
                method: 'PATCH',
                headers: {
                    "Content-type": 'application/json'
                },
                body: JSON.stringify(data)
            },
        })
    }
}

export default new UserController()