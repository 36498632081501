import { changeToGhostAdmin, isAuth } from "@/actions/auth"
import EmptyMessage from "@/components/ui/complex/empty-action"
import { InputSearch } from "@/components/ui/input-search"
import { searchRegex } from "@/helpers/regex"
import { Building2 } from "lucide-react"
import { useRouter } from "next/router"
import { useMemo, useState } from "react"
import CompanyItemCard from "./CompanyItemCard"

interface CompaniesListProps {
    companies: any[],
    onOpenChange: (value: boolean) => void,
    onSelectCompany: (id: string) => void
}

export default function CompaniesList({ companies = [], onOpenChange, onSelectCompany }: CompaniesListProps) {
    const [searchValue, setSearchValue] = useState('');

    const router = useRouter()
    const currentUser = isAuth();

    const handleViewCompany = (companyId: string) => {
        const userRoles = currentUser?.roles ?? [];

        if (!userRoles.includes('admin')) {
            return changeToGhostAdmin(() => {
                router.push(`/comercial/${companyId}`)
                onOpenChange(false)
            });
        };

        onOpenChange(false)
        router.push(`/comercial/${companyId}`)
    }

    const filteredGroups = useMemo(() => {
        let groupsList = companies

        if (searchValue) {
            const regex = searchRegex(searchValue);

            groupsList = groupsList.filter((group) => {
                return regex.test(group.name || group.displayName) || regex.test(group._id) || regex.test(group.countryName)
            });
        }

        return groupsList.sort((a, b) => (a.name || a.displayName).localeCompare(b.name || b.displayName))
    }, [companies, searchValue])

    return (
        <div>
            <InputSearch
                type="text"
                autoFocus
                value={searchValue}
                onChangeValue={setSearchValue}
                placeholder={'Buscar grupo...'}
                className="py-4.5 border rounded focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0 mb-2 bg-gray-50 text-[12px]"
            />
            <div className="flex flex-col max-h-[450px] overflow-y-auto border rounded">
                {
                    (filteredGroups.length == 0)
                        ? <EmptyMessage
                            message="Sin resultados"
                            description="No se encontró el grupo que buscabas..."
                            icon={<Building2 size={26} className="text-gray-700" />}
                            classNameCard="border-none"
                        />
                        : (
                            <ul className="grid">
                                {
                                    filteredGroups.map((company, index) => (
                                        <CompanyItemCard
                                            onPressViewCompany={() => handleViewCompany(company._id)}
                                            onSelectCompany={() => onSelectCompany(company._id)}
                                            isLastItem={index == filteredGroups.length - 1}
                                            searchValue={searchValue}
                                            company={company}
                                            key={company._id}
                                        />
                                    ))
                                }
                            </ul>
                        )
                }
            </div>
            <p className="text-gray-600 font-medium text-[13px] mt-2">
                {filteredGroups.length} empresas
            </p>
        </div>
    )
}

