import { GooglePlace } from "@/interfaces/Interface"

interface Address {
    location?: boolean,
    street_number?: string,
    api_version?: 1 | 2,
    route?: string,
    neighborhood?: string,
    locality?: string,
    administrative_area_level_1?: string,
    administrative_area_level_2?: string,
    country?: string,
    postal_town?: string,
    postal_code?: string,
    formatted_address?: string,
    place_id?: string,
    geoLocation?: {
        type: "Point",
        coordinates: [number, number]
    },
    extraInfo?: string
}

const transformAddress = (
    address: Pick<GooglePlace, 'id' | 'formattedAddress' | 'location' | 'addressComponents'>,
    extraInfo = ""
): Address => {
    let value: Address = {
        location: true,
        place_id: address.id,
        api_version: 2,
        formatted_address: address.formattedAddress,
        geoLocation: {
            type: "Point",
            coordinates: [address.location.longitude, address.location.latitude]
        },
        extraInfo
    }

    address.addressComponents.forEach(component => {
        component.types.filter((key) => key != "political").map((key) => {
            value[key] = component.longText
        })
    })

    return value;
}

export default transformAddress;