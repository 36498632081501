import { Skeleton } from "@/components/ui/skeleton";

export default function AddressLoader() {
    return (
      <div className='w-full grid space-y-1 p-1'>
        {
          Array.from({ length: 6 }).map((__, index) => (
            <Skeleton className="rounded-sm h-6 w-full " key={index} />
          ))
        }
      </div>
    )
  }