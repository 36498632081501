import { Button } from '@/components/ui/button';
import { SnoozeInteractionType } from '@/interfaces/Interface';
import { handleUpdate } from '@/redux/slices/crm';
import { handleLocalInteractions } from '@/redux/slices/thunks/crm';
import contactService from 'actions/crm/newContact';
import { formatDuration, intervalToDuration } from 'date-fns';
import { es } from 'date-fns/locale';
import dayjs from 'dayjs';
import { TimerReset } from 'lucide-react';
import mixpanel from 'mixpanel-browser';
import { useDispatch, useSelector } from 'react-redux';

interface SnoozeInteractionProps {
    interaction: SnoozeInteractionType,
    contact: string
}

export default function SnoozeInteraction({ interaction, contact }: SnoozeInteractionProps) {

    const dispatch = useDispatch();
    const localId = useSelector((state: any) => state.crm.localInteractionId);

    const handleReminder = async () => {
        const date = dayjs().add(interaction.snooze.value, interaction.snooze.unit);
        const isoDate = date.toISOString();

        try {
            dispatch(handleLocalInteractions({
                values: { reminderDate: date.toISOString() },
                type: 'reminder',
                contact
            }));

            const result: any = await contactService.createReminder({
                data: { reminderDate: isoDate },
                localId: localId + 1,
                id: contact
            });

            if ('events' in result) {
                for (let event of result.events) {
                    dispatch(handleUpdate(event))
                };
            }
        } catch (error) {
            mixpanel.track("Snooze error", { contactId: contact, reminderDate: isoDate })
            console.error(error)
        }
    };

    return (
        <div className="w-full justify-end pr-[41px] flex">
            <Button
                className="flex p-2.5 pl-3.5 pr-5 flex-col items-center border bg-gray-100/70 font-medium hover:bg-gray-200/30 justify-start h-max"
                onClick={handleReminder}
                type="button"
                animateClick
            >
                <div className="flex">
                    <TimerReset size={24} className='text-gray-800 shrink-0 mt-1 mr-1.5' />
                    <div className='flex flex-col w-full text-start'>
                        <h5 className="font-medium text-gray-800 text-[14px]">
                            Esperar respuesta
                        </h5>
                        <p className='text-[12px] font-normal  text-gray-500 leading-4'>
                            {formatSnooze(interaction.snooze)}
                        </p>
                    </div>
                </div>
            </Button>
        </div>
    )
}

const formatSnooze = (snooze: { unit: 'hours' | 'minutes' | 'seconds' | 'days', value: number }) => {

    if (snooze.unit == 'hours' && snooze.value <= 24) {
        if (snooze.value == 1) return `${snooze.value} hora`;
        return `${snooze.value} horas`;
    };

    const duration = intervalToDuration({
        start: 0, end: snooze.value * {
            seconds: 1000,
            minutes: 60 * 1000,
            hours: 60 * 60 * 1000,
            days: 24 * 60 * 60 * 1000
        }[snooze.unit]
    });

    return formatDuration(duration, { locale: es });
};