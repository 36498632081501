import { getCompany } from "@/actions/auth"
import {
  Accordion
} from "@/components/ui/accordion"
import { validateSubconversationCreation } from "@/helpers/crm"
import { Feature, getPersonalFeature, isFeatureEnabled, PersonalFeature } from "@/helpers/featureFlagHelpers"
import { fetcher } from "@/helpers/fetcher"
import { API } from '@/httpConfig'
import { Contact } from "@/interfaces/crm/contact"
import { getInteractions, setMenuInformationControl, setSearchValue, setSectionInformationContact } from "@/redux/slices/crm"
import { useQuery } from "@tanstack/react-query"
import _ from "lodash"
import { ArrowRight, X } from "lucide-react"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import useDebounce from "../core/functions/useDebouce"
import { Button } from "../ui/button"
import ProspectDetailItem from "./ContactInformationMenu/prospect-detail-item"
import RequestItem from "./ContactInformationMenu/request-item"
import SalesItems from "./ContactInformationMenu/sales-items"
import ShortcutsItem from "./ContactInformationMenu/shortcuts-item"
import StatsItem from "./ContactInformationMenu/stats-item"
import SubConversationsList from "./ContactInformationMenu/sub-conversations-list"
import InputSearchInteraction from "./Inputs/InputSearchInteraction"
import FiltersSection from "./Prospect/FiltersSection"
import { SubMenuCrm } from './sub-menu-crm'

type TSections = "filters" | "information"
const titles = {
  filters: "Filtrar por",
}

export interface IProsContactInformationMenu {
  openMenuInformation: boolean
  toggleMenuInformation: () => void
  downChatFc?: () => void
  readonly prospect: Contact
  fixedMenu?: boolean
  handleUpdateContact: () => void
}

// TODO ELIMINAR EN UN FUTURO:
const viasonoCompanyId = '66a11dd9b61392347f215808'
// const viasonoCompanyId = '626b06d2b42f3fe2a74ecfd4'


export default function ContactInformationMenu({
  openMenuInformation,
  toggleMenuInformation,
  downChatFc,
  fixedMenu = false,
  prospect,
  handleUpdateContact,
}: IProsContactInformationMenu) {
  const [isFirstSearch, setFirstSearch] = useState(false)
  const [isEqualQuery, setEqualQuery] = useState(false)
  const refMenu = useRef<HTMLDivElement | null>(null)

  const sectionActive = useSelector((state: any) => state.crm.sectionInformationContact)
  const activeFilters = useSelector((state: any) => state.crm.activeInteractionFilters)
  const isLoadingFilteredInteractions = useSelector((state: any) => state.crm.isLoadingFilteredInteractions)
  const interactionsList = useSelector((state: any) => state.crm.interactions)
  const searchValue: string = useSelector((state: any) => state.crm.searchValue)
  const menuInformationControl = useSelector((state: any) => state.crm.menuInformationControl)

  const searchDebounce = useDebounce(searchValue, 450)
  const countActiveFilters: number = useMemo(() => Object.values(activeFilters)?.flat().length, [activeFilters])

  const dispatch = useDispatch()
  const handleSelectSection = (section: TSections) => dispatch(setSectionInformationContact(section))

  const companyId = _.get(getCompany(), '_id', '');

  const { data, error } = useQuery({
    enabled: !!(companyId == viasonoCompanyId && prospect?._id),
    queryKey: ['external-contact-stats', prospect?._id || ''],
    queryFn: () => {
      return fetcher<{ success: boolean, stats: any[] }>({
        url: `${API}/integration/viasono/nodum/statistics?id=${prospect?._id}`,
        defaultContentType: true,
        isExternalURL: true,
        withToken: true
      })
    },
  });

  const stats = data?.stats || [];

  useEffect(() => {
    if (
      !isFirstSearch &&
      ((searchDebounce as string).trim() !== "" || Object.keys(activeFilters).length > 0)
    ) {
      setFirstSearch(true)
    }
    downChatFc && downChatFc()
  }, [searchDebounce, activeFilters])

  useEffect(() => {
    if (
      isFirstSearch &&
      ((searchDebounce as string).trim() !== "" ||
        Object.values(activeFilters).flat().length > 0)
    ) {
      handleGetInteraction(activeFilters, searchDebounce)
      setEqualQuery(false)
    } else {
      if (!isEqualQuery && isFirstSearch) {
        handleGetInteraction({}, "")
        setEqualQuery(true)
      }
    }
  }, [searchDebounce, activeFilters, isFirstSearch])

  const handleGetInteraction = (filters, searchValue) => {
    const contactId = prospect._id
    if (contactId) {
      dispatch(
        getInteractions({
          filters,
          searchValue,
          id: contactId,
        })
      )
    }
  }

  const handleControlAccordion = (values: string[]) => dispatch(setMenuInformationControl(values))
  const isSubconversationCreationEnabled = validateSubconversationCreation(prospect);

  return (
    <React.Fragment>
      <SubMenuCrm
        ref={refMenu}
        className={`modifyScroll w-[280px] xl:w-[290px] relative h-full flex ${openMenuInformation ? "" : "-ml-[290px] translate-x-full duration-200"
          } border-r border-r-blue-100 flex-col fixed h-[100vh] right-0 bg-white ${!fixedMenu ? "xl:relative" : ""
          } overflow-y-auto transition-all z-30 ease-out top-0 border-l`}
        rightButton={
          <Button
            size={'icon'}
            variant={'ghost'}
            className={
              `hover:text-[#8f95a3] justify-end hover:bg-transparent z-20 relative ${fixedMenu ? '' : 'xl:hidden'}  [&>svg]:w-[15px] [&>svg]:h-[15px]`
            }
            onClick={() => toggleMenuInformation()}
          >
            <X />
          </Button>
        }
        headerActive={false}
        title={"Información"}
        classNameHeader="px-4 pt-4 h-[40px]"
        titleElement={
          sectionActive !== "information" && (
            <div>
              <button
                className="flex items-center gap-2"
                onClick={() => handleSelectSection("information")}
              >
                <ArrowRight className="rotate-180 w-[17px] h-[17px]" />
                <h3 className="font-semibold text-[14px] uppercase">
                  {titles[sectionActive]}
                </h3>
              </button>
            </div>
          )
        }
      >
        <div className="p-4">
          {sectionActive == "information" && (
            <>
              <div className="mb-4">
                <InputSearchInteraction
                  value={searchValue as string}
                  count={countActiveFilters as number}
                  onChange={(value) => dispatch(setSearchValue(value))}
                  isLoading={isLoadingFilteredInteractions}
                  onAction={() => handleSelectSection("filters")}
                />
              </div>
              <Accordion
                type="multiple"
                value={menuInformationControl}
                onValueChange={handleControlAccordion}
                className="w-full flex flex-col flex-grow"
              >
                <ShortcutsItem prospect={prospect} />

                <ProspectDetailItem
                  handleUpdateContact={handleUpdateContact}
                  interactions={interactionsList}
                  prospect={prospect}
                />
                {
                  (isFeatureEnabled(Feature.ENABLE_REQUEST_SALE) &&
                    !getPersonalFeature<boolean>(PersonalFeature.DISABLE_REQUEST_SALE, false)) && (
                    <RequestItem prospect={prospect} />
                  )
                }
                {isSubconversationCreationEnabled && (<SubConversationsList prospect={prospect} />)}
                <SalesItems contactId={prospect._id} />
                <StatsItem stats={stats} />
              </Accordion>
            </>
          )}
          {
            sectionActive === "filters" && (
              <FiltersSection
                activeFilters={activeFilters}
                isLoading={isLoadingFilteredInteractions}
                sectionActive={sectionActive}
                handleChangeSection={(value) =>
                  handleSelectSection(value as TSections)
                }
              />
            )
          }
        </div>
      </SubMenuCrm>
      <div
        className={`w-full z-[10] h-[100%] bg-[#000000]/30 fixed top-0 
                ${openMenuInformation ? "block" : "hidden"} md:hidden left-0`}
        onClick={() => toggleMenuInformation()}
      ></div>
    </React.Fragment>
  )
}
