import { Button } from '@/components/ui/button'
import { Card, CardContent, CardTitle } from '@/components/ui/card'
import ButtonWithTooltip from '@/components/ui/custom/button-with-tooltip'
import useGetContactRequest from '@/hooks/useGetContactRequest'
import { useToggle } from '@/hooks/useToggle'
import { setRequestSaleId, toggleOpenRequestSale } from '@/redux/slices/crm'
import { Chip, Collapse } from '@mui/material'
import { Copy } from 'lucide-react'
import moment from 'moment'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SelectRequestSaleTemplate } from '../requestsale/RequestSale'
import RequestSaleAdd from '../requestsale/RequestSaleAdd'
import DetailAccordionItem from './detail-accordion-item'
import { PlusButton } from './sub-conversations-list'

export default function RequestItem({ prospect }) {
    const [openAddSale, openAddSaleTemplate] = useSelector((state: any) => [state.crm.requestsaleid, state.crm.requestsaletemplateid])
    const [openSelectRequestSaleType, setOpenSelectRequestSaleType] = useState(false)
    const [showAll, toggleShowAll] = useToggle()
    const requests = useGetContactRequest()
    const dispatch = useDispatch()

    const { templates = [] } = useSelector((state: any) => state.crm.requestSale) || {};

    const handleEditRequest = (value: boolean | string) => {
        dispatch(setRequestSaleId(value))
    }

    const onOpenRequestSaleList = () => {
        if (templates.length == 1) return dispatch(toggleOpenRequestSale(templates[0]._id))
        setOpenSelectRequestSaleType(true)
    }

    return <>
        {
            !!openAddSale && (
                <RequestSaleAdd
                    templateid={openAddSaleTemplate}
                    setOpen={handleEditRequest}
                    contactid={prospect._id}
                    presaleid={openAddSale}
                />

            )
        }
        <DetailAccordionItem label="Solicitudes" value="value-3">
            {
                openSelectRequestSaleType ?
                    <SelectRequestSaleTemplate goBack={() => setOpenSelectRequestSaleType(false)} />
                    : (
                        <div className='py-2'>
                            <div className="flex mb-2 items-center w-full justify-end">
                                <PlusButton onClick={onOpenRequestSaleList} />
                            </div>
                            {
                                requests.length > 0 && <ul className='mb-2 flex flex-col gap-2'>
                                    {
                                        requests.slice(0, 3).map((request) => (
                                            <li key={request._id}>
                                                <RequestCard
                                                    handleEditRequest={handleEditRequest}
                                                    request={request}
                                                    key={request._id}
                                                />
                                            </li>
                                        ))
                                    }
                                    <Collapse in={!!showAll} style={{ marginTop: 0 }}>
                                        <ul className='flex flex-col gap-2'>
                                            {
                                                requests.slice(3, requests.length).map((request) => (
                                                    <li key={request._id}>
                                                        <RequestCard
                                                            handleEditRequest={handleEditRequest}
                                                            request={request}
                                                            key={request._id}
                                                        />
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </Collapse>
                                </ul>
                            }
                            <div>
                                {
                                    requests.length > 3 && <Button onClick={toggleShowAll} className='w-full mb-2 text-[13px]' size={'xs'} variant={'default'}>
                                        {
                                            showAll
                                                ? 'Ver menos'
                                                : ' Ver mas'
                                        }
                                    </Button>
                                }
                            </div>
                        </div>
                    )
            }
        </DetailAccordionItem >
    </>
}

export function RequestCard({ request, handleEditRequest, onCloneRequestSale }) {
    const { template, createdAt, statusType, status } = request || {}

    return <div className='w-full relative'>
        <Card
            onClick={() => handleEditRequest(request._id)}
            className='flex hover:bg-accent cursor-pointer items-center hadow-none rounded-md pb-0 mb-0'
        >
            <CardContent className="flex flex-col p-2 px-3 relative h-full  w-full">
                <div className="flex items-center gap-2">
                    <CardTitle className='text-[16px] text-gray-800'>
                        {template.title}
                    </CardTitle>
                    <Chip size="small" style={{
                        fontSize: '11px',
                        padding: '0px',
                        height: '18px',
                    }} label={status} color={generatePreSaleColor(statusType)} />
                </div>
                <p className="text-[13px] text-gray-400">
                    {moment(createdAt).calendar()}
                </p>

            </CardContent>
        </Card>
        <div className="absolute top-0 bottom-0 right-3 flex items-center justify-center my-auto">
            <ButtonWithTooltip
                variant={'outline'}
                className="w-8 h-8"
                tooltip='Copiar'
                size={'icon'}
                animateClick
                type="button"
                onClick={(ev) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                    onCloneRequestSale();
                }}
            >
                <Copy size={16} className='text-gray-700' />
            </ButtonWithTooltip>
        </div>
    </div>
}

const generatePreSaleColor = (status) => {
    const statusColors = {
        'draft': 'default',
        'new': 'primary',
        'processing': 'secondary',
        'processed': 'secondary',
        'linked': 'success',
        'cancel': 'error',
        'resend': 'warning'
    };

    return statusColors[status] || statusColors['draft']
}

