
import { cn } from '@/lib/utils'
import { PropsWithChildren } from 'react'

type DateProps = PropsWithChildren<{
    classNameContainer?: string
}>

export default function DateBubble({
    classNameContainer,
    children
}: DateProps) {
    return (
        <div className={cn("w-full flex gap-5 justify-center items-center text-[16px]", classNameContainer)}>
            <div className="p-1 font-medium rounded-sm bg-white shadow-bubble-right px-5 text-[12px] text-[#6B7280]">
                {children}
            </div>
        </div>
    )
}
