import { getTransferableTo } from '@/actions/group'
import { Feature, isFeatureEnabled } from '@/helpers/featureFlagHelpers'
import { searchRegex } from '@/helpers/regex'
import { role } from '@/models/User'
import { useQuery } from '@tanstack/react-query'
import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { CustomCheckbox } from '../ui/checkbox'
import Chip, { TailwindColor } from '../ui/chip'
import InputWithSelect, { IOption } from '../ui/input-with-select'
import { UserAvatar } from '../ui/user-avatar'

export default function InputAgentTransfer({
    value,
    onChangeValue,
    agentProspect
}: {
    value: any,
    onChangeValue: (value: any) => void,
    agentProspect?: string
}) {
    const [showOthersTeams, setShowOthersTeams] = React.useState(false)
    const [inputValue, setInputValue] = React.useState<string>('')
    const enableShowAllSellersInTransfer = isFeatureEnabled(Feature.SHOW_ALL_SELLERS_IN_TRANSFER) || isFeatureEnabled(Feature.SHOW_ALL_AGENTS_IN_TRANSFER_FOR_ALL_USERS)

    const { data, isLoading } = useQuery({
        queryKey: ['list-of-sellers'],
        queryFn: getTransferableTo
    });

    const usersCurrentTeam = data?.users || []
    const otherUsersOfTeams = data?.otherUsersOfTeams || []

    const userSelected = useSelector((state: any) => state.crm.userInbox)

    const users = useMemo(() => {
        const usersToUse = showOthersTeams ? otherUsersOfTeams : usersCurrentTeam
        if (!Array.isArray(usersToUse)) return []

        if (!agentProspect) return usersToUse
        return usersToUse.filter((u) => u._id !== agentProspect)
    }, [data, userSelected, showOthersTeams, otherUsersOfTeams, usersCurrentTeam])

    const filteredAgents = useMemo(() => {
        let users_exists = new Map()

        return users.filter((agent) => {
            if (users_exists.has(agent._id)) return false
            users_exists.set(agent._id, true)
            return searchRegex(inputValue).test(`${agent.name} ${agent.lastname}`);
        })
    }, [users, inputValue]);

    const serialize = useCallback((agent) => ({ label: `${agent?.name} ${agent?.lastname}`, value: agent, extras: agent }), [users])
    const options = useMemo(() => filteredAgents.map(serialize), [filteredAgents])

    const GetItemRole = (roles: string[]) => {
        let label: string = '', color: TailwindColor | '' = '';

        const isEnableRole = (role: role) => roles.includes(role)

        if (isEnableRole('super')) {
            label = 'Supervisor'
            color = 'purple'
        } else if (isEnableRole('comercial')) {
            label = 'Comercial'
            color = 'orange'
        } else if (isEnableRole('project_manager')) {
            label = 'Gestor de proyecto'
            color = 'blue'
        }

        if (!label || !color) return null;

        return <Chip color={color} withCircle={false} className='text-[11px] h-[17px]'>
            {label}
        </Chip>
    };

    const RenderItem = ({ label, extras }: IOption) => {
        const { roles = [], photo, lastname, name } = extras || {}

        return (
            <div className='flex items-center gap-2'>
                <UserAvatar user={{ lastname, name, photo }} className='w-[20px] text-white h-[20px]' />
                <p>{label}</p>
                {GetItemRole(roles)}
            </div>
        )
    };

    const onHandleChangeTeamSelected = () => {
        if (!enableShowAllSellersInTransfer) return;
        setInputValue('')
        onChangeValue(undefined)
        const newValue = !showOthersTeams
        setShowOthersTeams(newValue)
    }

    return (
        <div className='flex flex-col justify-end'>
            {
                (enableShowAllSellersInTransfer && otherUsersOfTeams.length > 0) && (
                    <div className='flex items-center justify-end w-full mb-1 mt-[-29px] gap-2'>
                        <button className='font-medium h-7 text-[12px] text-primary' type="button" onClick={onHandleChangeTeamSelected}>
                            {
                                showOthersTeams
                                    ? 'Mostrar mi equipo'
                                    : 'Mostrar otros equipos'
                            }
                        </button>
                        <CustomCheckbox
                            classNameIcon={"w-[11px] h-[11px]"}
                            key={`${showOthersTeams}`}
                            checked={showOthersTeams}
                            onClick={onHandleChangeTeamSelected}
                            className={'w-[19px] rounded-sm h-[19px]'}
                        />
                    </div>
                )
            }
            <InputWithSelect
                strict={true}
                placeholder="Seleccionar agente a transferir..."
                onChangeInputSearch={(value) => setInputValue(value)}
                onChangeValue={onChangeValue}
                key={users.length}
                isLoading={isLoading}
                closeWithSelect
                emptyIndicator={`Sin resultados para "${inputValue}"`}
                value={value && { label: `${value?.name} ${value?.lastname}`, value }}
                renderItem={RenderItem}
                options={options}
            />
        </div>
    )
}