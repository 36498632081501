import { Button } from "@/components/ui/button";
import { SharedOption } from "@/interfaces/Interface";
import { PencilLine, Trash2 } from "lucide-react";
import { PropsWithChildren } from "react";

interface OptionItemProps {
    option: SharedOption,
    onDelete: () => void,
    onEdit: () => void,
}

export default function OptionItem({ option, onDelete, onEdit }: OptionItemProps) {
    return (
        <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
                <div
                    className="w-[15px] h-[15px] border rounded-full shrink-0 mr-1.5"
                    style={{ backgroundColor: option.backgroundColor }}
                />
                {option.name}
            </div>
            <div className="flex items-center space-x-1.5">
                <ButtonAction onClick={onDelete}>
                    <Trash2 size={14} className="shrink-0" />
                </ButtonAction>
                <ButtonAction onClick={onEdit}>
                    <PencilLine size={14} className="shrink-0" />
                </ButtonAction>
            </div>
        </div>
    )
}

type ButtonActionProps = PropsWithChildren<{ onClick: () => void }>;

function ButtonAction({ onClick, children }: ButtonActionProps) {
    return (
        <Button
            onClick={(ev) => {
                ev.stopPropagation();
                onClick();
            }}
            className="w-6 h-6"
            variant="outline"
            size={'icon'}
            type="button"
        >
            {children}
        </Button>
    )
}
