import { CardTitle } from '@/components/ui/card'
import { MessageCircleMore } from '@/icons'
import { SmsBubbleProps } from '@/interfaces/Interface'
import dayjs from 'dayjs'
import InteractiveMessageCard from './InteractiveMessageCard'

export default function SmsBubble({
  profile,
  hiddenProfile,
  interaction
}: SmsBubbleProps) {
  const { agent, content, createdAt } = interaction || {}
  const { origin, message } = content

  return <InteractiveMessageCard
    className='border bg-white border-gray-300'
    hiddenProfile={hiddenProfile}
    profile={profile}
    origin={origin}
    agent={agent}
  >
    <div className="flex flex-col h-full bg-white">
      <div className="w-full flex items-center justify-between py-[10px] px-4 bg-gray-100">
        <CardTitle className='text-gray-700 text-[14px] flex items-center gap-1.5'>
          <MessageCircleMore className='stroke-gray-700 w-[18px] h-[18px]' />
          SMS
        </CardTitle>
        <span className={'text-gray-500 text-[13px]'}>
          {dayjs(createdAt).format('HH:mm')}
        </span>
      </div>
      <div className='px-4 flex flex-col gap-2 py-[10px]'>
        <p className="text-[13px] font-medium text-gray-700">
          {message}
        </p>
      </div>
    </div>
  </InteractiveMessageCard>
}
