import { getCompany } from "@/actions/auth"
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog"
import _ from "lodash"
import "moment/locale/es"
import { useState } from "react"
import { SelecteableCompanyUsers } from "./SelecteableCompanyUsers"


interface CompanyModalProps {
    dialogTrigger: React.ReactElement
}

export const MyCompanyModal = ({ dialogTrigger }: CompanyModalProps) => {
    const [open, onOpenChange] = useState(false)

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogTrigger asChild>
                {dialogTrigger}
            </DialogTrigger>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader>
                    <DialogTitle>Cambiar cuenta</DialogTitle>
                    <DialogDescription>
                        Selecciona el usuario al que deseas cambiar de cuenta
                    </DialogDescription>
                    <SelecteableCompanyUsers
                        company={_.get(getCompany(), '_id', '')}
                        onOpenChange={onOpenChange}
                    />
                </DialogHeader>
            </DialogContent>
        </Dialog>
    )
}