import { isAuth } from '@/actions/auth'
import { getUnassignedCount } from '@/actions/crm/contact'
import { setInboxCount } from '@/redux/slices/headers'
import { useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

export default function useGetUnassignedCount() {
    const [contactCount, setContactCount] = useState<number>(0);
    const [originsCount, setOriginsCount] = useState<({ _id: string, count: number })[]>([])

    const user = useMemo(() => isAuth(), [])
    const dispatch = useDispatch()

    const { data, ...rest } = useQuery({
        enabled: (_.get(user, 'productType', '') == "ventia_freelancer" || _.get(user, "jailUrl", []).length > 0),
        queryKey: ['unassigned-contact-count'],
        queryFn: getUnassignedCount,
        refetchInterval: 60000
    });

    useEffect(() => {
        if (!data) return;
        const count = data.count || 0;

        setContactCount(count);
        dispatch(setInboxCount(count));
        setOriginsCount(data.origins);
    }, [data])

    const originsToMap = useMemo(() => {
        const originsMap: Record<string, number> = {};

        const getOrigins = (list: any[]) => {
            list.forEach((origin) => {
                if ('origins' in origin) return getOrigins(origin.origins);
                originsMap[origin._id] = origin.count;
            });
        };

        getOrigins(originsCount);
        return originsMap;
    }, [originsCount])

    return {
        contactCount,
        originsToMap,
        data,
        ...rest
    }
}
