import { toast } from '@/components/ui/use-toast'
import { getBase64 } from '@/helpers'
import { uploadFileToS3ByObject } from '@/helpers/uploadFileToS3'
import useUploadFile, { IFileToReturn } from '@/hooks/useUploadFile'
import { setEnableSaveRequestSale } from '@/redux/slices/crm'
import { CloudUpload } from '@mui/icons-material'
import { Typography } from '@mui/material'
import axios from 'axios'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { CardFile } from '../EmailInput/CardFile'

interface UploadMultipleFileInputProps {
    onChange: (value: any[]) => void,
    value: any[],
    title: string,
    disabled?: boolean,
    required?: boolean,
    code: string
}

const UploadMultipleFileInput = ({
    onChange,
    value = [],
    title,
    disabled,
    required,
    code
}: UploadMultipleFileInputProps) => {
    const [error, setError] = useState<string | null>(null)
    const [isHovering, setIsHovering] = useState(false);
    const dispatch = useDispatch()

    const dropZoneId = useMemo(() => {
        return `drop-zone-${code}`;
    }, [title])

    console.log(dropZoneId)

    const onHandleAddFile = async (files: IFileToReturn[]) => {
        if (!files.length) return setError('El tamaño del archivo es superior al permitido')
        if (disabled) return;
        const filesList = [...value, ...files];
        dispatch(setEnableSaveRequestSale(false))

        await uploadFileToS3ByObject(filesList, {
            getPreview: ({ index, file }) => {
                filesList[index] = file
                onChange(filesList)
            },
            onError: (error, file, index) => {
                filesList.splice(index, 1);
                const errorMessage = error instanceof Error ? error.message : error;
                toast({
                    title: 'Error al subir el archivo',
                    description: `Ocurrió un problema al intentar subir el archivo "${file.name}". Detalles del error: ${errorMessage}`,
                    variant: 'destructive'
                });
            }
        })

        onChange(filesList.map(({ name, path, type, size }) => ({
            uploadedFrom: 'web',
            name,
            path,
            type,
            size
        })));

        dispatch(setEnableSaveRequestSale(true))
    }

    const { getInputProps, getRootProps, isDragActive, isFileDialogActive } = useUploadFile({
        onChangeValue: onHandleAddFile
    })

    const onHandleDeleteFile = (inx: number) => {
        const allValues = [...value]
        allValues.splice(inx, 1)
        onChange(allValues)
    }

    const onHandlePaste = async (event: ClipboardEvent) => {
        if (!isHovering) return;

        const text = event.clipboardData?.getData('text');
        if (text && text.startsWith('http')) {
            try {
                const response = await axios.get(text, { responseType: 'blob' });
                const contentType = response.headers['content-type'] || '';
                const extension = contentType.split('/')[1] || 'file';
                const fileName = text.split('/').pop() || `archivo.${extension}`;

                const file = new File([response.data], fileName, { type: contentType });
                const base64 = await getBase64(file);

                onHandleAddFile([{
                    name: file.name,
                    path: base64,
                    type: file.type,
                    size: file.size
                }] as IFileToReturn[]);
            } catch (error) {
                console.log(error);

                toast({
                    title: 'Error al descargar el archivo',
                    description: `No se pudo descargar el archivo desde la URL: ${text}`,
                    variant: 'destructive'
                });
            }
        }
    }

    useEffect(() => {
        if (isFileDialogActive) {
            setError(null)
        }
    }, [isFileDialogActive])

    useEffect(() => {
        document.addEventListener('paste', onHandlePaste);
        return () => document.removeEventListener('paste', onHandlePaste);
    }, [value, isHovering])

    useEffect(() => {
        const dropZone = document.getElementById(dropZoneId)
        if (!dropZone) return;

        const handleMouseEnter = () => setIsHovering(true);
        const handleMouseLeave = () => setIsHovering(false);

        dropZone.addEventListener('mouseenter', handleMouseEnter);
        dropZone.addEventListener('mouseleave', handleMouseLeave);

        return () => {
            dropZone.removeEventListener('mouseenter', handleMouseEnter);
            dropZone.removeEventListener('mouseleave', handleMouseLeave);
        }
    }, []);

    return (
        <div id={dropZoneId}>
            <Typography fontSize={12} mb={0.5} color="#555">{title}{required ? '*' : ''}</Typography>
            <div {...(!disabled ? getRootProps() : {})} className={disabled ? 'pointer-events-none opacity-70' : ''}>
                <div className={`border-dashed border-2  rounded-md w-full py-7 flex-col flex items-center justify-center gap-2 ${isDragActive ? 'border-primary' : 'border-[#ccc]'}`}>
                    <input  {...(!disabled ? getInputProps() : {})} />
                    <CloudUpload sx={{ fontSize: '50px', mb: 0.5 }} />
                    <h5 className='font-medium text-gray-800 text-[13px]'>
                        {
                            isDragActive
                                ? 'Suelta el archivo aquí'
                                : 'Arrastra una imagen/archivo, presiona para seleccionar o pega una URL'
                        }
                    </h5>
                    <p className='text-gray-500 text-[12px]'>Debe ser menor a 50 MB</p>
                    {error && <p className='text-red-500 text-[12px]'>{error}</p>}
                </div>
            </div>
            <ul className='grid w-full gap-2 mt-2'>
                {
                    value.map((file, inx) => (
                        <div key={`file-${inx}`}>
                            <CardFile
                                handleDeleteFile={() => onHandleDeleteFile(inx)}
                                hiddenButtonDelete={disabled}
                                file={file as any}
                            />
                        </div>
                    ))
                }
            </ul>
        </div>
    )
}

export default UploadMultipleFileInput
