import { Button } from "@/components/ui/button";
import { Command, CommandEmpty, CommandGroup, CommandItem, CommandList } from "@/components/ui/command";
import { Input } from '@/components/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { highlightMatch } from "@/helpers/highlightMatch";
import usePlacesService from "@/hooks/usePlacesService";
import { GooglePlace } from "@/interfaces/Interface";
import { cn } from "@/lib/utils";
import { Collapse } from '@mui/material';
import { Check, ChevronsUpDown } from "lucide-react";
import React, { useEffect, useMemo, useState } from 'react';
import AddressLoader from "./AddressLoader";
import transformAddress from "./transformAddress";

interface AddressInputProps {
  askExtraInfo: boolean,
  onAddressChange: (address?: any) => void,
  address: any,
  disabled?: boolean,
  required: boolean,
  placeholder?: string
  classNameTriggerBtn?: string
}

export default function AddressInputShadcn({
  askExtraInfo,
  onAddressChange,
  address,
  required,
  classNameTriggerBtn,
  disabled,
  placeholder
}: AddressInputProps) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState("")
  const [searchValue, setSearchValued] = useState<string>('')

  const {
    isPlacePredictionsLoading,
    getPlacePredictions,
    placePredictions
  } = usePlacesService({ debounce: 500 });

  useEffect(() => {
    if (address) setValue(address.formatted_address)
  }, [address])

  const handleChange = (place: Pick<GooglePlace, 'id' | 'formattedAddress' | 'location' | 'addressComponents'>) => {
    const parsedPlace = transformAddress(place);
    onAddressChange(parsedPlace);
  }

  const generatePrediction = (text: string) => getPlacePredictions({ input: text });

  const predictions = useMemo(() => {
    return placePredictions.map(({ addressComponents, formattedAddress, location, id }) => ({
      addressComponents,
      formattedAddress,
      location,
      id
    }));
  }, [placePredictions])

  const onViewInMap = () => {
    if (!address || !address.location) return;
    const [longitude, latitude] = address?.geoLocation?.coordinates || [];

    window.open(
      `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`,
      '_blank'
    );
  };

  return (
    <>
      <Popover open={open} onOpenChange={setOpen} modal={true}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            disabled={disabled}
            role="combobox"
            aria-expanded={open}
            className={cn(`w-full font-normal h-10 overflow-hidden relative justify-between ${disabled ? 'cursor-not-allowed' : ''}`, classNameTriggerBtn)}
          >
            <span className="flex-1 max-w-max truncate overflow-hidden" >
              {value || placeholder}
            </span>
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="sm:w-[320px] w-[220px] p-0">
          <Command>
            <Input
              className={`flex h-10 w-full bg-transparent rounded-none border-x-0 border-t-0 py-3 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0 ${predictions.length == 0 && !searchValue.trim() ? 'border-b-0' : ''}`}
              placeholder="Busca una dirección..."
              onChange={({ target: { value } }) => {
                setSearchValued(value)
                generatePrediction(value)
              }}
            />
            {
              isPlacePredictionsLoading
                ? <AddressLoader />
                : (predictions.length == 0 && !!searchValue.trim())
                  ? <CommandEmpty>Dirección no encontrada.</CommandEmpty>
                  : (predictions.length > 0 && !!searchValue.trim()) && <CommandList
                    className="max-h-[300px] overflow-y-auto py-1 relative"
                  >
                    <CommandGroup className='py-0'>
                      {
                        predictions.map((place) => {
                          return (
                            <CommandItem key={place.id} onSelect={() => {
                              setValue(place.formattedAddress)
                              handleChange(place)
                              setOpen(false)
                            }}>
                              <div className='flex items-center'>
                                <Check
                                  className={cn(
                                    "mr-2 h-4 w-4",
                                    value === place.formattedAddress ? "opacity-100" : "opacity-0"
                                  )}
                                />
                                <span>
                                  {highlightMatch(place.formattedAddress, searchValue)}
                                </span>
                              </div>
                            </CommandItem>
                          )
                        })
                      }
                    </CommandGroup>
                  </CommandList>
            }
          </Command>
        </PopoverContent>
      </Popover>
      <Collapse in={askExtraInfo && address && address.location}>
        <div className='mt-2'>
          <Input
            placeholder="Nota dirección"
            value={address?.extraInfo}
            className='w-full'
            onChange={(e) => onAddressChange({ ...address, extraInfo: e.target.value })}
          />
        </div>
        <div className='pt-2 w-full'>
          <Button
            type="button"
            variant={'link'}
            className="w-full"
            onClick={onViewInMap}
          >
            Ver en mapa
          </Button>
        </div>
      </Collapse>
    </>
  )
}