"use client"
import { SaleInteraction } from "@/interfaces/crm/contact";
import { SaleBubbleTemplate } from "@/interfaces/Interface";
import MultilineTable from "./MultilineTable";
import SaleBubbleLayout from "./SaleBubbleLayout";

interface MultilineViewProps {
    template?: SaleBubbleTemplate | null,
    interaction: SaleInteraction,
    saleTitle: string,
    bubbleTitle?: string
}

export default function MultilineView(props: MultilineViewProps) {
    const { interaction, saleTitle, template, bubbleTitle = 'Venta registrada' } = props;
    const sale = interaction?.content?.sale || {};
    const { createdAt } = interaction || {};

    return (
        <SaleBubbleLayout
            title={bubbleTitle}
            createdAt={createdAt}
            classNameContent="max-w-[500px]"
        >
            <div>
                <div className="flex items-center mb-1 gap-2">
                    <h3 className='text-[16px] font-medium text-gray-700'>
                        {saleTitle}
                    </h3>
                    {
                        (!saleTitle && sale.type) && (
                            <span className="font-medium text-gray-500 text-[14px]">
                                {sale.type}
                            </span>
                        )
                    }
                </div>
                <MultilineTable
                    template={template}
                    sale={sale}
                />
            </div>
        </SaleBubbleLayout>
    )
}
