import { Badge } from '@/components/ui/badge'
import { UNASSIGNED_LOCK_TIME_SECONDS } from '@/helpers/globalVariables'
import { AlarmClock } from 'lucide-react'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

export default function CountUnassignedPill({ handleAfkError, }) {
    const [seconds, setSeconds] = useState(UNASSIGNED_LOCK_TIME_SECONDS)

    const endDate = useSelector((state: any) => state.crm.contactUnassignedDate)
    const resetCountContactUnassigned = useSelector((state: any) => state.crm.resetCountContactUnassigned)

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(Math.floor(moment(endDate).toDate().getTime() / 1000 - new Date().getTime() / 1000));
        }, 200);
        return () => clearInterval(interval);
    }, [endDate])

    useEffect(() => {
        if (seconds == 0) {
            handleAfkError()
        }
    }, [seconds])

    useEffect(() => {
        if (resetCountContactUnassigned !== null) {
            setSeconds(UNASSIGNED_LOCK_TIME_SECONDS)
        }
    }, [resetCountContactUnassigned])

    return <Badge variant={'outline'} className="relative z-[1] bg-white">
        <div className="flex items-center gap-2 py-1">
            <AlarmClock size={16} />
            Reservado por {seconds} segundos
        </div>
    </Badge>
}
