import { getCompany } from "@/actions/auth";
import _ from "lodash";

const identificationTerms = {
    rut: {
        ar: "CUIT",
        uy: "RUT",
        pe: "RUC",
        co: "NIT",
        cl: "RUT",
        py: "RUC",
        mx: "RFC",
        bo: "NIT",
        br: "CNPJ",
        es: "NIF",
        cr: "Cédula Jurídica",
        ve: "RIF",
    }
};

export type Country = 'ar' | 'uy' | 'pe' | 'co' | 'cl' | 'py' | 'mx' | 'bo' | 'br' | 'es' | 'cr' | 've';
export type Term = 'rut';

export const getIdentificationTermByCountry = (country: Country, term: Term, defaultValue?: string) => {
    return _.get(identificationTerms, `${term}.${country.toLowerCase()}`, defaultValue);
};

export const getIdentificationTermByCompany = (term: Term): string => {
    const company = getCompany();
    const country = _.get(company, 'country', 'uy');
    return getIdentificationTermByCountry(country, term) as string
};