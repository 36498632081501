import { getCompany, updateLocalCompany } from "@/actions/auth"
import InputWithSelect from "@/components/ui/input-with-select"
import { searchRegex } from "@/helpers/regex"
import { useToggle } from "@/hooks/useToggle"
import { SharedOption } from "@/interfaces/Interface"
import _ from "lodash"
import { Plus } from "lucide-react"
import { useEffect, useMemo, useState } from "react"
import DeleteSharedOptionModal from "./DeleteSharedOptionModal"
import OptionItem from "./OptionItem"
import SharedOptionModal from "./SharedOptionModal"
import ValueOptionItem from "./ValueOptionItem"


interface SharedOptionsFieldProps {
    value?: string[],
    onChangeValue: (value: string[]) => void,
    field: {
        sharedOptions: SharedOption[],
        type: 'sharedmultioptions',
        name: string,
        code: string
    }
}

export default function SharedOptionsField({ field, onChangeValue, value = [] }: SharedOptionsFieldProps) {
    const [sharedOptions, setSharedOptions] = useState<SharedOption[]>(field.sharedOptions ?? []);
    const [optionToEdit, setOptionToEdit] = useState<undefined | SharedOption>(undefined);
    const [optionToDelete, setOptionToDelete] = useState<undefined | SharedOption>();
    const [searchValue, setSearchValue] = useState<string>('');
    const [openAddOption, toggleAddOption] = useToggle();

    useEffect(() => {
        setSharedOptions(field.sharedOptions ?? [])
    }, [field])

    const options = useMemo(() => {
        return sharedOptions.map((field) => ({
            value: field.code,
            label: field.name,
            extras: field
        }));
    }, [sharedOptions])

    const filteredOptions = useMemo(() => {
        if (!searchValue) return options ?? [];

        const regex = searchRegex(searchValue);
        return options.filter((option) => regex.test(option.label));
    }, [searchValue, options]);

    const onSuccessCreateOrUpdateOption = (result: SharedOption) => {
        let sharedOptionsList = [...sharedOptions];
        const index = sharedOptionsList.findIndex((field) => field.code == result.code);

        if (index >= 0) {
            sharedOptionsList[index] = result;
        } else {
            sharedOptionsList.push(result);
            onChangeValue([...value, result.code]);
        }

        setSharedOptions(sharedOptionsList);

        let companyAdditionalDataFields = _.get(getCompany(), 'additionalDataFields', []);
        const fieldIndex = companyAdditionalDataFields.findIndex((f) => f.code == field.code);

        if (fieldIndex == -1) return;

        companyAdditionalDataFields[fieldIndex].sharedOptions = sharedOptionsList;
        updateLocalCompany('additionalDataFields', companyAdditionalDataFields);
    }

    const onSuccessDelete = (option: SharedOption) => {
        const optionCode = option?.code;
        const sharedOptionsList = [...sharedOptions].filter((option) => option.code !== optionCode);

        setSharedOptions(sharedOptionsList);

        let companyAdditionalDataFields = _.get(getCompany(), 'additionalDataFields', []);
        const fieldIndex = companyAdditionalDataFields.findIndex((f) => f.code == field.code);

        if (fieldIndex == -1) return;

        companyAdditionalDataFields[fieldIndex].sharedOptions = sharedOptionsList;
        updateLocalCompany('additionalDataFields', companyAdditionalDataFields);
    }

    const downElements = useMemo(() => {
        return [
            {
                onSelectAction: toggleAddOption,
                render: (
                    <div
                        className='text-primary text-[13px] w-full h-full flex items-center font-medium'
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            toggleAddOption();
                        }}
                    >
                        <Plus className='mr-1' size={17} />
                        Añadir opción
                    </div>
                )
            }
        ]
    }, []);

    return (
        <>
            <InputWithSelect
                multiple={true}
                value={options.filter((option) => value.includes(option.value))}
                placeholder="Seleccionar una opción..."
                onChangeInputSearch={setSearchValue}
                onChangeValue={onChangeValue}
                downElements={downElements}
                options={filteredOptions}
                renderItem={(option) => (
                    <OptionItem
                        onDelete={() => setOptionToDelete(option.extras)}
                        option={option.extras}
                        onEdit={() => {
                            setOptionToEdit(option.extras);
                            toggleAddOption();
                        }}
                    />
                )}
                renderValueItem={(option, onDeleteOption) => (
                    <ValueOptionItem
                        onDeleteOption={onDeleteOption}
                        option={option.extras}
                    />
                )}
            />

            <SharedOptionModal
                onSuccessResult={onSuccessCreateOrUpdateOption}
                initialValues={{ name: searchValue }}
                option={optionToEdit}
                fieldCode={field.code}
                open={openAddOption}
                onOpenChange={() => {
                    toggleAddOption();
                    setOptionToEdit(undefined);
                }}
            />

            <DeleteSharedOptionModal
                onOpenChange={() => setOptionToDelete(undefined)}
                onSuccessDelete={onSuccessDelete}
                open={!!optionToDelete}
                option={optionToDelete}
                fieldCode={field.code}
            />
        </>
    )
}