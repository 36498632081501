import { Button } from "@/components/ui/button"
import { Card, CardDescription, CardTitle } from "@/components/ui/card"
import SimpleTooltip from "@/components/ui/simple-tooltip"
import { formatFileSize } from "@/helpers/formatFileSize"
import { IFile } from "@/helpers/getFileWithBase64"
import { ArrowDownToLine, XIcon } from "lucide-react"
import { FileIcon } from "../Bubble/Icons/Crm"
import { UploadFileStatus } from "@/helpers/uploadFileToS3"
import ProgressBar from "@/components/ui/progress-bar"

interface CardFileProps {
    file: (IFile) & { progress?: number, status?: UploadFileStatus },
    handleDeleteFile: () => void,
    hiddenButtonDelete?: boolean
}

export function CardFile({
    file,
    hiddenButtonDelete = false,
    handleDeleteFile
}: CardFileProps) {
    const { name, size, type = '', path = '', progress, status } = file || {}
    const isImage = type.includes('image');

    const progressEnable = typeof progress == 'number'

    const onHandleDownloadFile = () => {
        const linkElement = document.createElement('a')
        linkElement.href = path
        linkElement.download = name
        linkElement.click()
    }

    return (
        <Card className='w-full flex gap-4 bg-gray-50 justify-between p-2 items-center  rounded-[5px] '>
            <div className='flex-1 flex items-center gap-2.5'>
                <div className={`w-[60px] shrink-0  relative overflow-hidden flex items-center justify-center border rounded-md  h-[60px] ${isImage ? 'cursor-pointer' : ''}`}>
                    {
                        isImage
                            ? <img src={path} alt={name} className="w-full h-full object-cover" />
                            : <FileIcon className='w-[30px] h-[40px]' />
                    }
                </div>
                <div className='flex flex-col w-full'>
                    <CardTitle className='text-[14px] max-w-[250px] text-gray-800 truncate'>
                        {name}
                    </CardTitle>
                    <CardDescription className='text-[13px]'>
                        {formatFileSize(size || 0)}
                    </CardDescription>
                    {
                        progressEnable && (
                            <ProgressBar className="max-w-[80%]" progress={progress} />
                        )
                    }
                </div>
            </div>
            <div className="flex items-center gap-2">
                <CardButton
                    onClick={onHandleDownloadFile}
                    tooltip={'Descargar'}
                >
                    <ArrowDownToLine size={17} />
                </CardButton>
                {
                    !hiddenButtonDelete && (
                        <CardButton
                            onClick={handleDeleteFile}
                            tooltip={'Eliminar'}
                        >
                            <XIcon size={17} />
                        </CardButton>
                    )
                }
            </div>
        </Card>
    )
}

export function CardButton({
    children,
    onClick,
    tooltip
}) {
    return (
        <SimpleTooltip content={tooltip}>
            <Button
                size={'icon'}
                variant={'outline'}
                type={'button'}
                onClick={onClick}
                className="w-[25px] shrink-0 h-[25px] text-gray-800 right-2"
            >
                {children}
            </Button>
        </SimpleTooltip>
    )
}