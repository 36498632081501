import {
  CardDescription
} from "@/components/ui/card"
import { namesOfOptions } from "@/constants/modalReminder"
import { IReminderBubble } from "@/interfaces/Interface"
import { LocalStatus } from "@/redux/libs/ts/interfaces"
import { handleSetLocalStatus } from "@/redux/slices/crm"
import { handleApplyEvents } from "@/redux/slices/thunks/crm"
import contactService from 'actions/crm/newContact'
import dayjs from "dayjs"
import "dayjs/locale/es"
import { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import InteractiveMessageCard, { InteractonStatusType } from "../InteractiveMessageCard"
import { InteractionStatus } from "../interaction-status"
import { HeaderReminderCard } from "./header-reminder-card"

const ReminderBubble = ({
  interaction,
  profile,
  hiddenProfile
}: IReminderBubble) => {
  const { content, agent, localStatus, createdAt, via } = interaction || {}
  const { reminderDate, actionType, comment, } = content
  const type = reminderDate ? "created" : "closed"
  const date = new Date(createdAt)

  const dataOfReminder = useMemo(() => namesOfOptions?.[actionType as any], [interaction, actionType])
  const isNotCreated = useMemo(() => type !== "created", [type, interaction])
  const socketId = useSelector((state: any) => state.crm.socketId)

  const dispatch = useDispatch()

  const handleRetry = () => {
    const { reminderDate, actionType, comment } = interaction.content || {}
    const values = { action: actionType, comment, reminderDate }
    const { _id: interactionId } = interaction || {}

    if (interactionId) {
      dispatch(handleSetLocalStatus({ localId: interactionId, status: LocalStatus.LOADING }))
      contactService
        .createReminder({ id: interaction.contact, data: values, localId: interactionId, socketIgnore: socketId })
        .then((res: any) => dispatch(handleApplyEvents(res.events)))
        .catch(() => dispatch(handleSetLocalStatus({ localId: interactionId })))
    }
  }

  if (isNotCreated) return <div className="w-full flex items-center justify-center">
    <div
      className={`xl:w-auto bg-white rounded-[6px] text-center shadow-bubble-right overflow-hidden h-auto`}
    >
      <HeaderReminderCard
        date={date}
        isNotCreated={true}
        type={type}
        via={via}
      />
    </div>
  </div>

  return <InteractiveMessageCard
    origin="agent"
    agent={agent}
    status={(localStatus as InteractonStatusType) || 'idle'}
    profile={profile}
    hiddenProfile={hiddenProfile}
    className={`md:max-w-[400px] md:min-w-[350px] max-w-[95%]`}
  >
    <div className={`bg-slate-100 overflow-hidden flex-grow items-center justify-center`}>
      <HeaderReminderCard
        isNotCreated={isNotCreated}
        date={date}
        type={type}
        via={via}
      />
      {!isNotCreated && (
        <div className="flex text-start flex-col p-4 py-2 bg-white pb-4 justify-start">
          <p
            className={`md:text-[14px] text-[12px] flex pr-2 lg:flex-row flex-col text-start gap-1 font-medium ${isNotCreated ? "text-red-500" : "text-slate-600"
              }`}
          >
            <span className="capitalize">
              {dayjs(reminderDate).format("dddd DD/MM/YY, HH:mm")}
            </span>
            {actionType && (
              <span className="[&>svg]:w-[15px] [&>svg]:h-[15px] flex gap-1 items-center">
                <span className="lg:block hidden">- </span>{" "}
                {actionType !== "other" && dataOfReminder?.icon}{" "}
                {dataOfReminder?.name || ""}
              </span>
            )}
          </p>
          {
            comment && (
              <CardDescription
                className={`md:text-[13px] text-[12px] font-medium ${isNotCreated ? "text-red-500" : "text-slate-500"}`}
              >
                {comment}
              </CardDescription>
            )
          }
          <InteractionStatus
            status={localStatus}
            handleRetry={handleRetry}
            messageError="No se pudo agendar el recordatorio."
          />
        </div>
      )}
    </div>
  </InteractiveMessageCard>
}

export default ReminderBubble