import { isAuth } from "@/actions/auth"
import { archive } from "@/actions/crm/contact"
import { handleLocalInteractions } from "@/redux/slices/thunks/crm"
import contactService from 'actions/crm/newContact'
import _ from "lodash"
import { AlarmClock, ArchiveRestore } from "lucide-react"
import mixpanel from "mixpanel-browser"
import moment from "moment"
import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button } from "../ui/button"
import { handleUpdate } from "@/redux/slices/crm"

export default function ActionsToRemember({
  interactions,
  status,
  reminder,
  archivingReason,
  prospectID,
}) {
  const [hiddenReminder, setHiddenReminder] = useState(false)
  const [hiddenArchiving, setHiddenArchiving] = useState(false)
  const contentReasonArchivings = useMemo(() => _.get(isAuth(), "group.archivingReasons", []), [])

  const localId = useSelector((state: any) => state.crm.localInteractionId)

  useEffect(() => {
    setHiddenReminder(false)
    setHiddenArchiving(false)
  }, [interactions, prospectID])

  const dispatch = useDispatch();

  const verifyReminder = useMemo(() => {

    if (interactions.length === 0) return false
    const lastInteraction = interactions[0]
    if (lastInteraction && lastInteraction.type === 'reminder' && !!lastInteraction.content.reminderDate) return false;

    const lastReminder = interactions.find(
      (interaction) =>
        interaction.type === "reminder" && interaction.content.reminderDate
    )

    if (!lastReminder) return false
    const dateLastReminder = new Date(lastReminder.content.reminderDate)

    if (
      lastReminder &&
      dateLastReminder > new Date() &&
      (status !== "reminder" || !reminder) &&
      !archivingReason
    )
      return lastReminder

    return false
  }, [interactions])

  const verifyArchiving = useMemo(() => {
    const lastInteraction = interactions[0]
    if (lastInteraction && lastInteraction.type === 'archiving' && lastInteraction.content.to !== '') return false;

    const lastArchiving = interactions.find(
      (interaction) => interaction.type === "archiving" && interaction?.content?.to !== ""
    )

    if (!lastArchiving || interactions.length == 0) return false

    const verifyReasons = contentReasonArchivings.find((reason) => reason.code === lastArchiving.content.to)

    return !archivingReason && verifyReasons ? verifyReasons : false
  }, [contentReasonArchivings, interactions])

  const handleReminder = async () => {
    if (!verifyReminder) return;
    const { reminderDate, actionType, comment } = verifyReminder.content ?? {};

    dispatch(handleLocalInteractions({
      values: { reminderDate, actionType, comment },
      contact: prospectID,
      type: 'reminder'
    }));

    try {
      const result: any = await contactService.createReminder({
        data: { action: actionType, comment, reminderDate },
        localId: localId + 1,
        id: prospectID
      });

      if ('events' in result) {
        for (let event of result.events) {
          dispatch(handleUpdate(event))
        };
      }
    } catch (error) {
      mixpanel.track("Reminder error", { contactId: prospectID, data: verifyReminder.content })
      console.error(error)
    }
  }

  const handleArchiving = () => {
    if (verifyArchiving) {
      const archivingReason = verifyArchiving.code

      archive(prospectID, archivingReason).then((response) => {
        if (response.error) {
          mixpanel.track("Archive created Error", {
            contactId: prospectID,
            reason: archivingReason,
            error: response.error,
          })
        } else {
          mixpanel.track("Archive created", {
            contactId: prospectID,
            reason: archivingReason,
          })
          setHiddenArchiving(true)
        }
      })
    }
  }

  console.log('Reminder to r111eminder', verifyReminder);

  if (!(verifyReminder || verifyArchiving)) return null

  return (
    <div className="flex items-center gap-3 top-5 relative flex-wrap justify-center w-full">
      {verifyReminder && !hiddenReminder && !reminder && (
        <ButtonAction
          description={moment(verifyReminder.content.reminderDate).format(
            "dddd DD/MM/YY, HH:mm"
          )}
          icon={<AlarmClock size={15} />}
          label={"Volver a recordar"}
          onAction={() => handleReminder()}
        />
      )}
      {verifyArchiving && !hiddenArchiving && (!archivingReason || archivingReason == "") && (
        <ButtonAction
          description={`Razón: ${verifyArchiving.name}`}
          icon={<ArchiveRestore size={15} />}
          label={"Volver a archivar"}
          onAction={() => handleArchiving()}
        />
      )}
    </div>
  )
}

export function ButtonAction({ icon, label, description, onAction }) {
  return (
    <Button
      className="flex flex-col items-center border bg-gray-100/70 font-medium hover:bg-gray-200/30 md:h-[60px] h-[54px] justify-center md:px-7 px-5"
      variant={"secondary"}
      onClick={onAction}
      animateClick
    >
      <span className="flex items-center text-gray-800 md:text-[13.5px] text-[12px] gap-[6px]">
        {icon}
        {label}
      </span>
      <p className="text-gray-500 capitalize  text-[11px]">
        {description}
      </p>
    </Button>
  )
}
