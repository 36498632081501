import { Skeleton } from "@/components/ui/skeleton";

export default function ChangeAccountLoaderData() {
    return <ul className="border rounded grid">
        {
            Array.from({ length: 5 }).map((__, inx) => {
                return (
                    <li key={inx}>
                        <div className={`flex gap-2 relative items-center justify-between p-3 ${inx < 4 ? 'border-b' : ''}`}>
                            <div className="flex space-x-2.5 w-full">
                                <Skeleton className="h-7 w-7 rounded-full" />
                                <div className="flex-grow">
                                    <Skeleton className="h-2.5 max-w-[170px] rounded-sm w-full mb-1" />
                                    <Skeleton className="h-2.5 max-w-[100px] rounded-sm w-full" />
                                </div>
                            </div>
                        </div>
                    </li>
                )
            })
        }
    </ul>
}
