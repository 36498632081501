import { deleteSharedOption } from '@/actions/crm/contact';
import CustomAlertDelete from '@/components/AlertDialogDelete/CustomAlertDelete';
import { toastActionError } from '@/components/comercial/comercialManagment/comissions/CommisionModal';
import { ModalPropsBase, SharedOption } from '@/interfaces/Interface';
import { useMutation } from '@tanstack/react-query';

interface DeleteSharedOptionModalProps extends ModalPropsBase {
    option?: SharedOption,
    fieldCode: string,
    onSuccessDelete?: (option: SharedOption) => void
}

export default function DeleteSharedOptionModal({
    onOpenChange,
    onSuccessDelete,
    option,
    fieldCode,
    open
}: DeleteSharedOptionModalProps) {

    const { isPending, mutate } = useMutation({
        mutationFn: () => deleteSharedOption(fieldCode, option?.code as string),
        onSuccess: () => {
            if (onSuccessDelete) onSuccessDelete(option as SharedOption);
            onOpenChange(false);
        },
        onError: toastActionError
    });

    const handleDelete = () => mutate();

    if (!option) return null;

    return (
        <CustomAlertDelete
            title="¿Estás completamente seguro?"
            description={`Si eliminas la opción "${option.name}", dejará de estar visible en todos los contactos que la tengan.`}
            handleCancel={() => onOpenChange(false)}
            handleConfirm={handleDelete}
            loading={isPending}
            open={open}
        />
    )
}
