import { Button } from "@/components/ui/button";
import { KeyboardArrowRight } from "@mui/icons-material";
import { ChevronDown } from "lucide-react";

interface FloatingButtonsProps {
    bajarChat: () => void;
    isVisible: boolean;
    nextContactVisible: boolean;
    nextConctact?: () => void;
}

const FloatingButtons = ({ bajarChat, isVisible, nextContactVisible, nextConctact }: FloatingButtonsProps) => {
    return (
        <div className="flex pr-5 space-x-2 items-center mb-4 z-[10]">
            <Button
                size={'icon'} variant={'ghost'}
                className={`sm:w-[45px] w-[40px] h-[40px] transition-opacity  ease-linear sm:h-[45px] bg-white hover:bg-[#fafafa] rounded-[10px] ${!isVisible ? 'opacity-100' : 'opacity-0'}`}
                style={{ boxShadow: '0px 6px 24px rgba(0 0 0 / 8%)' }}
                onClick={bajarChat}
                animateClick
            >
                <ChevronDown size={18} />
            </Button>
            {nextContactVisible &&
                <Button
                    className="ease-linear z-10"
                    variant="default"
                    animateClick
                    onClick={nextConctact}>
                    <KeyboardArrowRight />
                    Siguiente contacto
                </Button>
            }
        </div>
    )
}

export default FloatingButtons