import _ from "lodash"

export const CountryList = [
    { "name": "Argentina", "code": "ar" },
    { "name": "Belice", "code": "bz" },
    { "name": "Bolivia", "code": "bo" },
    { "name": "Brasil", "code": "br" },
    { "name": "Canadá", "code": "ca" },
    { "name": "Chile", "code": "cl" },
    { "name": "Colombia", "code": "co" },
    { "name": "Costa Rica", "code": "cr" },
    { "name": "Cuba", "code": "cu" },
    { "name": "Dominica", "code": "dm" },
    { "name": "Ecuador", "code": "ec" },
    { "name": "El Salvador", "code": "sv" },
    { "name": "España", "code": "es" },
    { "name": "Estados Unidos", "code": "us" },
    { "name": "Guatemala", "code": "gt" },
    { "name": "Guyana", "code": "gy" },
    { "name": "Haití", "code": "ht" },
    { "name": "Honduras", "code": "hn" },
    { "name": "Jamaica", "code": "jm" },
    { "name": "México", "code": "mx" },
    { "name": "Nicaragua", "code": "ni" },
    { "name": "Panamá", "code": "pa" },
    { "name": "Paraguay", "code": "py" },
    { "name": "Perú", "code": "pe" },
    { "name": "Puerto Rico", "code": "pr" },
    { "name": "República Dominicana", "code": "do" },
    { "name": "San Cristóbal y Nieves", "code": "kn" },
    { "name": "San Vicente y las Granadinas", "code": "vc" },
    { "name": "Santa Lucía", "code": "lc" },
    { "name": "Surinam", "code": "sr" },
    { "name": "Trinidad y Tobago", "code": "tt" },
    { "name": "Uruguay", "code": "uy" },
    { "name": "Venezuela", "code": "ve" }
];

export const mapOfcountryList = _.keyBy(CountryList, 'code');