import RolChip from "@/components/company/RolChip";
import { UserAvatar } from "@/components/ui/user-avatar";
import { highlightMatch } from "@/helpers/highlightMatch";
import { motion } from 'framer-motion';
import { useMemo } from "react";

interface UserItemCardProps {
    user: any,
    group: any,
    company: any,
    isLastItem?: boolean,
    isFirstItem?: boolean,
    searchValue?: string,
    onHandlePress?: () => void
}

export default function UserItemCard({
    user,
    group,
    company,
    isLastItem,
    onHandlePress,
    isFirstItem,
    searchValue = ''
}: UserItemCardProps) {

    const fullName = useMemo(() => [user.name, user.lastname].filter(Boolean).join(' '), [user]);
    const userRoles = user?.roles ?? [];

    return (
        <li>
            <motion.div
                onClick={onHandlePress}
                whileTap={{ scale: 0.99 }}
                className={`flex gap-2 hover:bg-slate-100 group items-center justify-between p-3 cursor-pointer ${!isLastItem ? 'border-b' : ''} ${isFirstItem ? 'border-t' : ''}`}
            >
                <div className="flex space-x-2.5">
                    <UserAvatar
                        className="w-7 h-7 mt-0.5 border"
                        user={user}
                    />
                    <div className="flex flex-col">
                        <h4 className="font-medium text-gray-800 select-none text-[15px]">
                            {highlightMatch(fullName, searchValue, (text) => {
                                return <span className="bg-yellow-200 text-black">{text}</span>
                            })}
                        </h4>
                        <p className="text-gray-600 text-xs select-none">
                            <span>{company._id == group._id ? 'Grupo principal' : group.name || group.displayName}</span>
                            <span className="mx-1">•</span>
                            <span>{highlightMatch(user.email, searchValue, (text) => {
                                return <span className="bg-yellow-200 text-black">{text}</span>
                            })}</span>
                        </p>
                        <div className="mt-1.5">
                            {userRoles.map((role, index) => (
                                <RolChip key={index} rol={role} userType={user?.userType} />
                            ))}
                        </div>
                    </div>
                </div>
            </motion.div>
        </li>
    )
}