import { Avatar } from "@/components/ui/avatar";
import { motion } from 'framer-motion';
import { ChevronRight, Flag } from 'lucide-react';

interface GroupCardItemProps {
    group: any,
    isLastItem?: boolean,
    onClick?: () => void,
    defaultCountry?: string
}

export default function SubGroupCardItem({ group, isLastItem, defaultCountry, onClick }: GroupCardItemProps) {
    return (
        <li>
            <motion.div
                className={`flex gap-2 hover:bg-slate-100 group items-center justify-between p-3 cursor-pointer ${!isLastItem ? 'border-b' : ''}`}
                onClick={onClick}
                whileTap={{ scale: 0.99 }}
            >
                <div className="flex space-x-2.5">
                <Avatar className="flex h-7 w-7 mt-0.5 border border-gray-300 overflow-hidden items-center justify-center">
                        {((group.country || defaultCountry) || group.logo) ? (
                            <img
                                className='w-full h-full object-cover'
                                src={group.logo || `https://flagcdn.com/w40/${(group.country || defaultCountry).toLowerCase()}.png`}
                                alt="company-logo-or-flag"
                            />
                        ) : (
                            <Flag size={18} />
                        )}
                    </Avatar>
                    <div>
                        <h4 className="font-medium text-gray-800 text-[15px]">
                            {`${group.name || group.displayName}`}
                        </h4>
                        <p className="text-gray-600 text-[12px]">
                            Subgrupo
                        </p>
                    </div>
                </div>
                <ChevronRight size={16} className="-translate-x-1 group-hover:translate-x-0.5 duration-100" />
            </motion.div>
        </li>
    )
}